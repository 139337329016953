import React from 'react';
import {
    FormControlLabel,
    Checkbox,
    FormHelperText,
    FormControl,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const ErrorCheckbox = withStyles({
    root: {
        color: '#f44336',
    },
})((props) => <Checkbox color="default" {...props} />);

const CheckboxTermsAndConditions = ({
    checked,
    onChange,
    labelComponent,
    hasError,
    errorMessage,
}) => {
    const RenderedCheckbox = hasError ? <ErrorCheckbox /> : <Checkbox />;

    return (
        <FormControl error={hasError}>
            <FormControlLabel
                control={RenderedCheckbox}
                onChange={(event) => onChange(event.target.checked)}
                checked={checked}
                label={labelComponent}
            ></FormControlLabel>
            {hasError ? (
                <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                    {errorMessage}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};

export default CheckboxTermsAndConditions;
