import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    calendarContainer: {
        overflow: 'hidden !important',
        height: '100vh !important',
        width: '100%',

        '& .MuiPickersStaticWrapper-staticWrapperRoot, .MuiPickersCalendarHeader-iconButton':
            {
                background: '#eff6ff',
            },
        '& .MuiPickersCalendar-week .MuiTypography-body2,.MuiTypography-caption':
            {
                fontSize: '12px !important',
            },
        '& .MuiPickersCalendar-week .MuiPickersDay-day': {
            width: '28px',
            height: '28px',
        },

        '& .MuiPickersBasePicker-container': {
            margin: '20px 0',
        },

        '& .fc-timegrid-slot-label-cushion': {
            fontSize: '0.875rem',
            fontFamily: "'Open sans', sans-serif",
            fontWeight: 400,
            lineHeight: 1.43,
        },
        '& .fc .fc-timegrid-slot': {
            marginRight: '10px',
            height: '50px !important',
        },

        '& .fc-theme-standard .fc-scrollgrid': {
            border: 'none !important',
        },
        '& .fc-timegrid-slot-label-frame': {
            textAlign: 'center !important',
        },
        '& .fc .fc-col-header-cell-cushion': {
            display: 'initial !important',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '32px',
            color: '#000',
        },
        '& .fc .fc-scroller': {
            overflow: 'auto !important',
        },
        '& .fc-timeGridWeek-button, .fc-timeGridDay-button': {
            padding: '2px 7px !important',
            fontSize: '14px !important',
        },

        '& .fc-col-header-cell-cushion': {
            fontWeight: 200,
            fontSize: '1rem',
            margin: '16px 0px',
        },

        '& .fc-toolbar-title': {
            fontWeight: 200,
        },

        '& .fc .fc-timegrid-slot': {
            height: '40px',
        },

        '& .fc .fc-timegrid-slot-label': {
            width: '100px !important',
        },

        '& .fc-timegrid-slot-label-cushion': {
            fontWeight: 100,
            fontSize: '0.8rem',
        },

        '& button.MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected':
            {
                background: '#5fb6d9',
            },

        '& .fc .fc-timegrid-col.fc-day-today': {
            backgroundColor: 'rgba(10, 255, 11, 0.1)',
            color: 'white',
        },
        '& .fc-timeGridWeek-button': {
            textTransform: 'capitalize !important',
        },
        '& .fc-timeGridWeek-button, .fc-timeGridDay-button': {
            textTransform: 'capitalize !important',
        },
        '& .fc .fc-button-primary': {
            backgroundColor: '#5fb6d9',
            border: 'none',
        },

        '& .fc .fc-button-primary:hover': {
            backgroundColor: '#5fb6d9',
            border: 'none',
        },

        '& .fc .fc-button-primary:active': {
            backgroundColor: '#5fb6d9 !important',
            border: 'none',
        },

        '& .MuiListItem-gutters': {
            color: 'black !important',
        },
        '& .fc .fc-button-primary:not(:disabled).fc-button-active': {
            fontWeight: 500,
            backgroundColor: '#557785',
        },

        '& .fc .fc-scroller-harness-liquid': {
            height: '80vh',
        },

        '& .fc-v-event': {
            border: 'none !important',
        },
    },

    datepicker: {
        padding: '40px 30px 30px !important',
        height: '100%',
        width: 'min-content',
        overflow: 'auto',

        background: '#eff6ff',

        '& .fc-theme-standard': {
            height: 'calc(100vh - 150px)',
            [theme.breakpoints.down('md')]: {
                marginTop: '30px !important',
            },
        },
        '& .MuiDateCalendar-root': {
            minWidth: 'unset !important',
            maxWidth: '100% !important',
        },
        '& .MuiPickersDay-root': {
            color: '#7d7987',
            fontWeight: '500 !important',
            fontSize: '12px !important',
            background: 'transparent',
        },
        '& .MuiDayCalendar-weekDayLabel': {
            color: '#7d7987',
            fontWeight: '500 !important',
            fontSize: '12px !important',
            background: 'transparent'
        },
        '& .Mui-selected': {
            color: '#fff',
            borderRadius: '18px',
            backgroundColor: '#5fb6d9 !important',
            '&:hover': {
              backgroundColor: '#5fb6d9',
            }
      },
    },

    datepickerTitle: {
        margin: '0 !important',
        fontSize: '24px !important',
        fontWeight: 'bold !important',
    },

    calendar: {
        padding: '30px 30px 0 !important',
        [theme.breakpoints.down('md')]: {
            padding: '30px 10px 0 10px !important',
        },

        '& .fc-theme-standard': {
            height: 'calc(100vh - 150px)',
            [theme.breakpoints.down('md')]: {
                marginTop: '30px !important',
            },
        },
        '& .MuiPickersBasePicker-pickerView': {
            minWidth: 'unset !important',
            maxWidth: '100% !important',
        },
        '& button.MuiButtonBase-root.MuiIconButton-root.MuiPickersCalendarHeader-iconButton, p.MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter':
            {
                color: '#7d7987',
                fontWeight: '700 !important',
                fontSize: '16px !important',
            },
        '& .MuiPickersCalendarHeader-switchHeader': {
            paddingBottom: '20px',
        },
        '& .MuiPickersCalendarHeader-dayLabel': {
            fontWeight: '700 !important',
        },
    },
}));
