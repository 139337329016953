import React, { useState, useEffect, useRef } from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { TextField, Popper, Paper, Grow, Snackbar } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'core/contexts/localization';

export const GooglePlacesAutocomplete = ({
    initialValue = '',
    citiesOnly = false,
    onSelect,
    parseDataToSet,
    locationId,
    addCoordinates,
}) => {
    const { t: translate } = useTranslation();

    const [address, setAddress] = useState(initialValue);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        setAddress(initialValue ?? '');
    }, [initialValue]);

    const handleSelect = (value, ...selection) => {
        if (value !== address) {
            geocodeByAddress(value)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => {
                    if (addCoordinates) {
                        addCoordinates(latLng.lng, latLng.lat);
                    }
                    parseDataToSet?.({
                        longitude: latLng.lng,
                        latitude: latLng.lat,
                    });
                })
                .catch((error) => console.error('Error', error));
            setAddress(value);
            // onSelect(value);
            // onSelect?.(value, ...selection);
            if (onSelect) onSelect(value);
            parseDataToSet?.({ location: value });
            setOpenSnackBar(true);
        }
    };

    const handleChange = (value) => {
        if (value !== address) {
            setAddress(value);
            parseDataToSet?.({ location: value });
        }
    };

    const anchorRef = useRef(null);

    return (
        <PlacesAutocomplete
            key={locationId}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            // searchOptions={{
            //   types: citiesOnly ? ['(cities)'] : undefined,
            // }}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => {
                const open = loading || !isEmpty(suggestions);

                const { width: minWidth } =
                    anchorRef.current?.getBoundingClientRect?.() ?? {};

                return (
                    <>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={openSnackBar}
                            autoHideDuration={1500}
                            onClose={() => setOpenSnackBar(false)}
                            message={translate('trip.view.destinations.added')}
                        />
                        <div ref={anchorRef}>
                            <TextField
                                style={{ width: '100%' }}
                                variant="outlined"
                                {...getInputProps({
                                    placeholder: translate(
                                        'trip.view.destinations.type_address'
                                    ),
                                })}
                            />
                        </div>
                        <Popper
                            style={{ zIndex: 1500 }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom'
                                                ? 'center top'
                                                : 'center bottom',
                                    }}
                                >
                                    <Paper
                                        style={{
                                            minWidth,
                                            marginTop: '4px',
                                            padding: '4px 8px',
                                        }}
                                    >
                                        {loading ? <div>...loading</div> : null}
                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor:
                                                    suggestion.active
                                                        ? '#41b6e6'
                                                        : '#fff',
                                                margin: '6px auto',
                                                maxWidth: '300px',
                                                cursor: 'pointer',
                                            };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        { style }
                                                    )}
                                                >
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </>
                );
            }}
        </PlacesAutocomplete>
    );
};
