import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

export const PaxMessage = ({ experienceInfoTemp }) => {
    const { t: translate } = useTranslation();

    const classes = useStyles();

    console.log({ pax: experienceInfoTemp.paxMessage });

    return (
        <div className={classes.modal}>
            <span className={classes.subtitle}>{translate('experienceProfile.saveModal.pax_message.subtitle1')}</span>

            <textarea
                className={classes.messageBody}
                placeholder={`${translate('experienceProfile.saveModal.pax_message.placeholder')}`}
                // value={experienceInfoTemp?.paxMessage || ''}
                defaultValue={experienceInfoTemp?.paxMessage || ''}
                onChange={(ev) => {
                    experienceInfoTemp.paxMessage = ev.target.value;
                }}
                maxLength={255}
            />
        </div>
    );
};
