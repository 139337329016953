import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: '1rem',
    },
    checkbox: {
        color: `${theme.palette.secondary.main} !important`,
    },
}));
