import React from 'react';
import { TextField, FormControl } from '@mui/material';

export const TextFieldAdapter = ({
    input,
    meta,
    label,
    testRef,
    disabled,
    onFieldChange,
}) => {
    return (
        <FormControl
            variant="outlined"
            error={meta.touched ? !!meta.error : false}
        >
            <TextField
                variant="outlined"
                disabled={disabled || false}
                inputRef={testRef}
                {...input}
                label={label}
                error={meta.touched ? !!meta.error : false}
                onChange={(event) => {
                    if (onFieldChange) onFieldChange(event.target.value);
                    input.onChange(event.target.value);
                }}
                helperText={meta.touched ? meta.error : ''}
            />
        </FormControl>
    );
};
