import { useState, useEffect } from 'react';
import {
    Popover,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid2,
    Checkbox,
    CircularProgress,
    TextField,
    FormControlLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'core/contexts/localization';

import { useStyles } from './styles';

const FutureEventsModal = ({
    show,
    onClose,
    x,
    y,
    anchorEl,
    save,
    onSave,
    onDelete,
}) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    return (
        <Popover
            onClose={onClose}
            open={show}
            anchorReference="anchorEl"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            disableRestoreFocus
            style={{ maxWidth: '80%' }}
        >
            <DialogTitle>
                <Grid2 container justifyContent="space-between">
                    <button
                        style={{
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            marginRight: 0,
                        }}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <CloseIcon />
                    </button>
                </Grid2>
            </DialogTitle>
            <style>
                {`
                .MuiDialogContent-root{
                    overflow-y: hidden;
                }`}
            </style>
            <DialogContent
                className={classes.content}
                style={{ marginTop: '-1.5rem' }}
            >
                <h2 style={{ textAlign: 'center' }}>
                    <strong>
                        {save
                            ? translate('form.future_events.save')
                            : translate('form.future_events.delete')}
                    </strong>
                </h2>
                <Grid2 container spacing={2} style={{ marginBottom: '1.5rem' }}>
                    <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                        <Button
                            style={{
                                width: '100%',
                                margin: '0.5rem 0',
                                color: '#fff',
                            }}
                            className={classes.bgOrange300}
                            onClick={() => {
                                if (save) {
                                    onSave();
                                } else {
                                    onDelete();
                                }
                            }}
                        >
                            {translate('form.future_events.no_none')}
                        </Button>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }}>
                        <Button
                            style={{
                                width: '100%',
                                margin: '0.5rem 0',
                                color: '#fff',
                            }}
                            className={classes.bgBlue300}
                            onClick={() => {
                                if (save) {
                                    onSave(true);
                                } else {
                                    onDelete(true);
                                }
                            }}
                        >
                            {translate('form.future_events.yes_all')}
                        </Button>
                    </Grid2>
                </Grid2>
            </DialogContent>
        </Popover>
    );
};

export default FutureEventsModal;
