import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { AppBar as MUIAppBar, useMediaQuery } from '@mui/material';

import MoneyDropDown from '../MoneyDropDown';
import LanguageDropDown from '../LanguageDropDown';

import { useAppBar } from './hook';

const AppBar = ({ open, isHome }) => {
    const location = useLocation();

    const { classes, role, setRole, onBecomeClick } = useAppBar(isHome);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const drawerWidth = isMobile ? '100vw' : 240;

    const appBarShiftStyle = open
        ? {
              width: `calc(100% - ${drawerWidth}px)`,
              marginLeft: drawerWidth,
              transition: (theme) =>
                  theme.transitions.create(['margin', 'width'], {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen,
                  }),
          }
        : {};

    return (
        <MUIAppBar elevation={0} className={clsx(classes.appBar)} style={appBarShiftStyle}>
            <div className={classes.toolbar}>
                <span className={classes.role}>
                    {
                        <>
                            {location.pathname === '/home' ? (
                                <>
                                    <LanguageDropDown />
                                    <MoneyDropDown />
                                </>
                            ) : null}
                        </>
                    }
                </span>
            </div>
        </MUIAppBar>
    );
};

AppBar.displayName = 'AppBar';

export default AppBar;
