import React, { useContext, useState } from 'react';

import { ExperienceService } from '../api/services/experiences';
import { useCurrency } from 'core/contexts/currency';

const ExperiencesContext = React.createContext(null);

export const ExperiencesProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [experience, setExperience] = useState({});
    const [experiences, setExperiences] = useState([]);
    const { currency, getCurrencyExchange } = useCurrency();
    const [timeOfArrival, setTimeOfArrival] = useState(null);
    const [contractExperiences, setContractExperiences] = useState([]);
    const [contractBundleExperiences, setContractBundleExperiences] = useState([]);
    const [experienceSchedule, setExperienceSchedule] = useState([]);

    const getExperiences = async (params) => {
        try {
            setLoading(true);
            const experiencesData = await ExperienceService.get(params);
            if (!experiencesData) {
                throw new Error();
            }
            setExperiences(experiencesData);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    // const getContractExperiences = async (
    //     code,
    //     priceOrder,
    //     modusesFilter,
    //     tourOperatorsId
    // ) => {
    //     try {
    //         setLoading(true);
    //         const qrData = await ExperienceService.getByHostel(
    //             code.toString(16),
    //             priceOrder,
    //             modusesFilter,
    //             tourOperatorsId
    //         );
    //         if (!qrData) {
    //             throw new Error();
    //         }

    //         setContractExperiences(qrData.experiences);

    //         return true;
    //     } catch (err) {
    //         console.error(err);
    //         return false;
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const getExperiencesUpdated = async (expId) => {
        try {
            setLoading(true);
            const experiencesData = await ExperienceService.getUpdated(expId || '');

            if (!experiencesData) {
                throw new Error();
            }
            setExperiences(experiencesData);
            return experiencesData;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const getExperiencesAllData = async (expId) => {
        try {
            setLoading(true);
            const experiencesData = await ExperienceService.getAllExperiences(expId || '');

            if (!experiencesData) {
                throw new Error();
            }
            // Home experiences expects you to set the state
            setExperiences(experiencesData);
            // Global bookings expects you to return the experiencesData
            return experiencesData;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getDateExperiences = async ({ date, disp, qty }) => {
        try {
            setLoading(true);
            let experiencesData = await ExperienceService.getDateExperiences(date, disp, qty);

            if (!experiencesData) {
                throw new Error();
            }

            experiencesData = await Promise.all(
                experiencesData.map(async (experience) => {
                    if (experience.currency == currency) {
                        return experience;
                    }
                    const currencyExchange = await getCurrencyExchange(experience.currency, currency);
                    console.log('exchange ===', experience.price, experience.currency, currencyExchange, currency, 'TOTAL==', parseFloat(experience.price) * parseFloat(currencyExchange));
                    experience.price = parseFloat(experience.price) * parseFloat(currencyExchange);
                    experience.price = experience.price.toFixed(2);
                    return experience;
                })
            );

            setExperiences(experiencesData);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getContractExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
        try {
            setLoading(true);
            const qrData = await ExperienceService.getByContract(code.toString(16), priceOrder, modusesFilter, tourOperatorsId);
            if (!qrData) {
                throw new Error();
            }

            //   setData(qrData.establishmentData)
            setContractExperiences(qrData.experiences);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const getContractBundleExperiences = async (code, priceOrder, modusesFilter, tourOperatorsId) => {
        try {
            setLoading(true);
            const qrData = await ExperienceService.getBundleByContract(code.toString(16), priceOrder, modusesFilter, tourOperatorsId);
            if (!qrData) {
                throw new Error();
            }
            // setData(qrData.establishmentData)
            setContractBundleExperiences(qrData.bundle);

            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getExperienceSchedule = async (range) => {
        try {
            if (!experience.id) {
                return;
            }
            setLoading(true);
            const result = await ExperienceService.getExperienceSchedule({ id: experience.id, range });
            if (!result) {
                throw new Error();
            }
            setExperienceSchedule(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const addExperienceSchedule = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.addExperienceSchedule(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const updateExperienceSchedule = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.updateExperienceSchedule(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteExperienceSchedule = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.deleteExperienceSchedule(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <ExperiencesContext.Provider
            value={{
                loading,
                experience,
                setExperience,
                getExperiences,
                experiences,
                getExperiencesUpdated,
                getDateExperiences,
                timeOfArrival,
                setTimeOfArrival,
                contractExperiences,
                getContractExperiences,
                contractBundleExperiences,
                getContractBundleExperiences,
                getExperiencesAllData,
                experienceSchedule,
                setExperienceSchedule,
                getExperienceSchedule,
                addExperienceSchedule,
                updateExperienceSchedule,
                deleteExperienceSchedule,
            }}
        >
            {children}
        </ExperiencesContext.Provider>
    );
};

ExperiencesProvider.displayName = 'ExperiencesProvider';

export const useExperiences = () => useContext(ExperiencesContext);
