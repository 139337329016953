import { useState } from 'react';
import { Drawer, Link, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton, useMediaQuery, ListItemButton } from '@mui/material';
import { ExitToApp, Close } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';
import WhiteLogo from 'assets/logo/white.png';
import ClosedMenuLogo from 'assets/logo/closed-menu.png';
import { experiencerMenu } from './items';
import TermsAndConditions from './TermsAndConditions';

const Menu = ({ open, toggleMenu }) => {
    const history = useHistory();
    const { t: translate } = useTranslation();

    const { logout, role } = useAuth();

    // const items = role === 'triper' ? triperMenu : experiencerMenu;
    const items = experiencerMenu;
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const drawerWidth = isMobile ? '100vw' : 240;

    const drawerStyles = {
        width: drawerWidth,
        flexShrink: 0,
    };
    const drawerOpenStyles = {
        width: drawerWidth,
        transition: (theme) =>
            theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
    };
    const drawerCloseStyles = {
        transition: (theme) =>
            theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        overflowX: 'hidden',
        width: 60,
    };

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);

    const onLogoutClick = async () => {
        await logout();
        history.push('/');
    };

    return (
        <>
            {isMobile && !open ? (
                <IconButton color="white" onClick={toggleMenu} edge="start" className={classes.menuButton} sx={{ zIndex: '1500 !important' }} size="large">
                    <MenuIcon />
                </IconButton>
            ) : null}
            <Drawer
                sx={drawerStyles}
                variant={isMobile ? 'persistent' : 'permanent'}
                anchor="left"
                open={open}
                PaperProps={{
                    sx: open ? drawerOpenStyles : drawerCloseStyles,
                }}
            >
                <div className={classes.header}>
                    {open ? <img alt="global-triper-logo" className={classes.logo} src={WhiteLogo} /> : <img alt="global-triper-logo" className={classes.closedMenuLogo} src={ClosedMenuLogo} />}

                    {isMobile && (
                        <IconButton style={{ color: 'white' }} onClick={() => toggleMenu()} size="large">
                            <Close />
                        </IconButton>
                    )}
                </div>
                <List className={classes.list}>
                    {!isMobile && (
                        <ListItem className={classes.openMenu} disablePadding onClick={toggleMenu}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <MenuIcon />
                                </ListItemIcon>
                            </ListItemButton>
                        </ListItem>
                    )}
                    {items.map(({ name, icon, link }) => (
                        <ListItem key={name} sx={{ display: 'block' }} disablePadding onClick={() => history.push(link)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <img
                                        alt={name}
                                        style={{
                                            maxWidth: '1.7em',
                                            maxHeight: '1.7em',
                                        }}
                                        src={icon}
                                    />
                                </ListItemIcon>
                                {!!open && <ListItemText primary={translate(name)} />}
                            </ListItemButton>
                        </ListItem>
                    ))}

                    <ListItem key={'sign-out'} disablePadding className={classes.signOut} onClick={onLogoutClick}>
                        <ListItemButton>
                            <ListItemIcon>
                                <ExitToApp />
                            </ListItemIcon>
                            {!!open && <ListItemText style={{ color: 'white' }} primary="Sign Out" />}
                        </ListItemButton>
                    </ListItem>

                    {!!open && (
                        <Typography className={classes.copyright}>
                            © Global Triper -
                            <Link
                                href=""
                                style={{ color: '#1890ff' }}
                                onClick={(e) => {
                                    setOpenModal(true);
                                    e.preventDefault();
                                }}
                            >
                                {` ${translate('termsAndConditions.privacyPolicy')}`}
                            </Link>
                        </Typography>
                    )}
                </List>
            </Drawer>
            <TermsAndConditions open={openModal} onClose={() => setOpenModal(false)} />
        </>
    );
};

Menu.displayName = 'Menu';

export default Menu;
