import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'core/contexts/localization';
import { Grid2 } from '@mui/material';
import { green } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { InputBase } from '@mui/material';
import { useState } from 'react';
import { useStyles } from './styles';

export const Enviroment = ({ ...props }) => {
    const enviromentData = props.experienceInfoTemp.envPolicies;
    const enviromentOtherData = enviromentData.otherDescription;
    const otherCheckInit = [];
    const setOtherCheckInit = () => {
        if (
            enviromentOtherData === null ||
            enviromentOtherData === '' ||
            enviromentOtherData === undefined
        ) {
            otherCheckInit.pop();
            otherCheckInit.push(false);
        } else {
            otherCheckInit.pop();
            otherCheckInit.push(true);
        }
    };

    setOtherCheckInit();

    const { t: translate } = useTranslation();
    const classes = useStyles();
    const [refreshModal, setRefreshModal] = useState(false);
    const [otherCheck, setOtherCheck] = useState(otherCheckInit[0]);
    const enviromentModalIndex = [
        {
            trashRecycling: translate(
                'experienceProfile.saveModal.enviroment.Trash'
            ),
        },
        {
            greenEnergy: translate(
                'experienceProfile.saveModal.enviroment.Renewable'
            ),
        },
        {
            foodWasteOpt: translate(
                'experienceProfile.saveModal.enviroment.Food'
            ),
        },
        {
            ngoCollaboration: translate(
                'experienceProfile.saveModal.enviroment.ONG'
            ),
        },
    ];

    const handleInput = (input) => {
        enviromentData[input] = !enviromentData[input];
        setRefreshModal(!refreshModal);
    };
    const handleInputChangeOther = (input) => {
        enviromentData.otherDescription = input;
        if (!(input === '')) {
            setOtherCheck(true);
            otherCheckInit.pop();
            otherCheckInit.push(true);
        } else {
            setOtherCheck(false);
            otherCheckInit.pop();
            otherCheckInit.push(false);
        }
    };

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <Grid2 style={{ maxWidth: 1024, margin: '20px 20px 20px 20px' }}>
            {enviromentModalIndex.map((entry, index) => {
                const objKey = Object.keys(entry);
                console.log(entry, objKey, 'hereee');

                return (
                    <CardActions
                        key={`${objKey}-${index}-cardActions`}
                        className={classes.cardAction}
                    >
                        <GreenCheckbox
                            key={`${objKey}-${index}-GreenCheckbox`}
                            id={`${objKey}-${index}-checkBox`}
                            color="#2F80ED"
                            checked={enviromentData[objKey]}
                            onClick={(e) =>
                                handleInput(e.target.id.split('-')[0])
                            }
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <Typography
                            id={`cardMedia-${index}-cardText2`}
                            key={`${objKey}-${index}-Typography`}
                            style={{ color: '#2F80ED' }}
                            variant="body2"
                            component="label"
                        >
                            {entry[objKey]}
                        </Typography>
                    </CardActions>
                );
            })}
            <CardActions className={classes.cardAction}>
                <GreenCheckbox
                    checked={otherCheck}
                    color="#2F80ED"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <InputBase
                    name="experienceSubTitle-alphaNumeric-subtitle"
                    placeholder={translate('experienceProfile.saveModal.other')}
                    defaultValue={enviromentData.otherDescription}
                    inputProps={{ 'aria-label': 'naked' }}
                    onChange={(e) => handleInputChangeOther(e.target.value)}
                />
            </CardActions>
        </Grid2>
    );
};
