import makeStyles from '@mui/styles/makeStyles';
import bgExperiencer from '../../../assets/images/home-experiencer.jpg';
import palette from 'core/libs/core-ui/theme/colors-palette';
export const useStyles = makeStyles((theme) => ({
    container: {
        padding: `80px 60px 80px 100px`,
        backgroundColor: theme.palette.colors.white,
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
                6
            )}`,
        },
    },
    gridContainer: {
        [theme.breakpoints.up('md')]: {
            background: `linear-gradient(
        rgba(255, 255, 255, 0.70), 
        rgba(255, 255, 255, 0.75)
      ), url(${bgExperiencer}) no-repeat center center local`,
        },
    },
    experiencerDataContainer: {
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    title: {
        height: theme.spacing(4),
    },
    profile: {
        backgroundColor: 'rgb(0 68 142 / 18%)',
        color: 'rgb(0,68,142)',
    },
    newExperienceButton: {
        marginLeft: '10px',
        float: 'right',
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.green,
        '&:hover': {
            backgroundColor: palette.utriper.darkgreen,
        },
    },
    newBookingButton: {
        float: 'right',
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.blue,
        '&:hover': {
            backgroundColor: palette.utriper.darkblue,
        },
    },

    toggleExperiencesButton: {
        float: 'right',
        cursor: 'pointer',
        fontSize: '8px',
        marginTop: '15px',
        color: 'white',
        fontWeight: 'bold',
        width: 'fit-content',
        boxSizing: 'fit-content',
        backgroundColor: 'grey',
        '&:hover': {
            backgroundColor: 'lightGrey',
        },
    },
}));
