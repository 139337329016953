import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    input: {
        '&::placeholder': {
            color: '#AFB0B9',
        },
        alignItems: 'center',
        backgroundColor: 'transparent',
        color: '#4F4F4F',
        fontSize: '24px',
        fontWeight: '500',
        fontFamily: 'DM Sans',
        borderRadius: '0px !important',
        border: 'none',
        overflow: 'hidden',
        outline: 'none',
        resize: 'none',
        width: '100%',
        height: '40px !important',
        padding: '0px !important',
        lineHeight: '40px',
        float: 'left',
    },
    subExperienceRow: {
        '&:hover': {
            background: 'rgba(1,1,1,0.03)',
        },
        borderRadius: '4px',
        display: 'default',
        padding: '0px !important',
    },
    subExperienceIndex: {
        width: '0px',
        height: '0px',
        marginBottom: '3px',
        marginRight: '8px',
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderLeft: '10px solid #2F80ED',
    },
    seNumber: {
        cursor: 'default',
        paddingLeft: '15px',
        fontSize: '16px',
        fontWeight: '400',
        fontFamily: 'DM Sans',
    },
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        paddingTop: '8px !important',
        paddingBottom: '6px !important',
    },
    rowHoverFix: {
        background: 'white !important',
    },
    index: {
        color: theme.palette.getContrastText(theme.palette.colors.persianGreen),
        backgroundColor: theme.palette.colors.persianGreen,
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    hoursFont: {
        width: '90%',
        margin: '0 5%',
        cursor: 'default',
        fontSize: '24px',
        fontWeight: '500',
        fontFamily: 'DM Sans',
        borderRadius: '0px !important',
        color: '#4F4F4F',
        height: '40px !important',
        lineHeight: '40px',
    },
}));
