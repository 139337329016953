import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Lens } from '@mui/icons-material';
import { Grid2, ListItemText, ListItemButton } from '@mui/material';
import { useStyles } from './styles';
import { useExperiences } from 'core/contexts/experience';
import { useAuth } from 'core/contexts/auth';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import ExperienceStatus from 'core/libs/status/ExperienceStatus/ExperienceStatus';
import { useTranslation } from 'core/contexts/localization';

const ExperiencesList = ({ showActive }) => {
    // Añadido prop showActive
    const classes = useStyles();
    const history = useHistory();
    const { getUserInfoSync } = useAuth();
    const { experiences, loading, getExperiencesUpdated } = useExperiences();
    const { i18n, t: translate } = useTranslation();

    useEffect(() => {
        async function fetchData() {
            return await getUserInfoSync();
        }
        fetchData().then((usr) => getExperiencesUpdated(usr?.experiencer?.id));
    }, []);

    // Filtrar experiencias según el estado y la condición del status
    const filteredExperiences = experiences?.filter((experience) => {
        if (showActive) {
            return experience.status !== 2; // Muestra experiencias activas
        } else {
            return experience.status === 2; // Muestra experiencias archivadas
        }
    });

    filteredExperiences?.sort((a, b) => b.status - a.status);

    return loading ? (
        <CircularIndeterminate centered={true} />
    ) : (
        filteredExperiences?.map((experience) => (
            <Grid2 key={experience?.id} size={{ xs: 12, sm: 6, md: 4 }}>
                <ListItemButton
                    className={classes.listItem}
                    onClick={() =>
                        history.push(`/experience/${experience?.id}`)
                    }
                >
                    <ListItemText
                        primary={experience?.name}
                        sx={{ padding: '8px' }}
                    />
                    <div className={classes.statusText}>
                        {ExperienceStatus.getTranslatedName(
                            ExperienceStatus.status,
                            experience.status,
                            i18n.language
                        )}
                    </div>
                    <Lens
                        className={`${classes.dotInactive} ${
                            experience.status === 1
                                ? classes.dotActive
                                : experience.status === 0
                                ? classes.dotInactive
                                : classes.dotArchived
                        }`}
                    />
                </ListItemButton>
            </Grid2>
        ))
    );
};

ExperiencesList.displayName = 'ExperiencesList';

export default ExperiencesList;
