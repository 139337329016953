import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },

    formTitle: {
        marginBottom: theme.spacing(2),
    },
    profileDiv: {
        [theme.breakpoints.down('sm')]: {
            display: 'block !important',
        },
        [theme.breakpoints.up('md')]: {
            display: 'grid !important',
            gridTemplateColumns: '300px auto',
            // gap: 20px;
            gap: '40px !important',
        },
    },
    leftColumn: {
        background: '#eff6ff',
        height: '100%',
    },
    leftColumnContent: {
        padding: '50px 30px !important',
        position: 'sticky !important',
        // top: 80px;
        // @media (max-width: 960px) {
        [theme.breakpoints.down('bg')]: {
            position: 'initial !important',
        },
    },
    rightColumn: {
        padding: '30px 40px 30px 0 !important',
        [theme.breakpoints.down('md')]: {
            padding: '30px 20px 30px 20px !important',
        },
    },
    sectionTitle: {
        marginBottom: '12px',
        fontWeight: 'bold !important',
    },
}));
