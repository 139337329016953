import { TextField } from '@mui/material';

import { useTranslation } from 'core/contexts/localization';

const TextInput = ({
    meta: { touched, error },
    input: { ...inputProps },
    ignoreError = false,
    ...props
}) => {
    const { t: translate } = useTranslation();
    // console.log(
    //   'TEXT INPUT===',
    //   inputProps,
    //   'PROPS====',
    //   props,
    //   'touched',
    //   touched,
    //   "error",
    //   error
    // );

    // if (props.textArea) {
    //   props = { ...props, multiline: true, rows: props.textArea.rows };
    //   console.log("NEW PROPS ====", props);
    // }

    // console.log('---------------------');
    // console.log('input name: ', inputProps.name);
    // console.log('is the field disabled? ', props.disabled);
    // console.log('other props passed in: ', props);
    // console.log('---------------------');

    return (
        <TextField
            {...inputProps}
            {...props}
            value={
                props.disabled && props.isUniqueInterval
                    ? props.intervalInMinutes
                    : props.disabled
                    ? ''
                    : inputProps.value
            }
            onChange={(event) => {
                // console.log("event==", event, "value==", event.target.value)
                if (props.onFieldChange)
                    props.onFieldChange(event.target.value);
                if (props.onTextFieldChange) props.onTextFieldChange(event);
                inputProps.onChange(event);
            }}
            error={!!(touched && error && !ignoreError)}
            helperText={touched && error && !ignoreError && translate(error)}
            fullWidth
            variant="outlined"
        />
    );
};

TextInput.displayName = 'TextInput';

export default TextInput;
