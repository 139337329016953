import { useMediaQuery } from '@mui/material';
import { useState, useRef } from 'react';

export const useCalendarPage = () => {
    const calendarRef = useRef(null);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [date, setDate] = useState(new Date());
    const [range, setRange] = useState({});
    const [tz, setTz] = useState(null);
    const [position, setCoordinates] = useState({ x: 0, y: 0 });
    const [open, setOpen] = useState(false);

    const onSelect = ({ startStr, endStr, jsEvent: { x, y } }) => {
        setCoordinates({ x, y });
        setRange({ from: startStr, to: endStr });
        setOpen(true);
    };

    const onDismiss = () => {
        setOpen(false);
    };

    const onSave = async (experiences) => {
        setOpen(false);
    };

    return {
        isSmall,
        date: {
            value: date,
            onChange: setDate,
        },
        calendar: {
            ref: calendarRef,
            onSelect,
            onDismiss,
            onSave,
            range,
            tz,
            setTz,
        },
        popOver: {
            show: open,
            position,
            setOpen,
        },
        filters: {},
    };
};
