import { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useHistory, Link } from 'react-router-dom';
import { useStyles } from './styles';

import { useTranslation } from 'core/contexts/localization';
import { useExperiences } from 'core/contexts/experience';
import { useAuth } from 'core/contexts/auth';
import { useBookings } from 'core/contexts/booking';

import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';
import { ExperienceService } from 'core/api/services/experiences';

import Layout from 'components/Layout';
import SaveModal from '../components/SaveModal/index.js';
import { SnackBar } from 'components/SnackBar';

import ProfileIcon from 'assets/icons/home/profile.svg';
import ScheduleIcon from 'assets/icons/home/schedule.svg';
import BookingIcon from 'assets/icons/home/bookings.svg';

import { Card, CardHeader, CardContent, Divider, Button } from '@mui/material';
import { Grid2, Typography, Tooltip } from '@mui/material';
import { check } from 'prettier';

export const EditExperience = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t: translate } = useTranslation();
    const { userInfo, getUserInfoSync, role } = useAuth();
    const { setFilteredExperiences } = useBookings();

    const [anchorEl, setAnchorEl] = useState(null);
    const [experienceData, setExperienceData] = useState();
    const { experiences, experience, setExperience, loading, getExperiences } =
        useExperiences();
    const [live, setLive] = useState(false);
    const [archived, setArchived] = useState(false);

    const expNum = window.location.href.split('/');
    // const [experiencerId, setExperiencerId] = 0; //parseFloat(expNum[expNum.length - 1]) - 1;
    const expId = parseFloat(expNum[expNum.length - 1]);
    const [open, setOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const [firstSubexperienceId, setFirstSubexperienceId] = useState(-1);
    const [forbidden, setForbidden] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        // getExperiences();
        // el exp id es de la experience, no del experiencer (tour operator)
        setExperience({});
        ExperienceInfoService.get(expId)
            .then(async (expData) => {
                const userData = await getUserInfoSync();
                if (
                    !userData.experiencer ||
                    !userData.experiencer.id ||
                    expData.experiencerId != userData.experiencer.id ||
                    role !== 'experiencer'
                ) {
                    throw new Error('Cannot edit another user experience!');
                }
                setExperienceData(expData);
                setExperience(expData);
                setLive(expData.status == 1 ? true : false);
                setArchived(expData.status == 2 ? true : false);

                setForbidden(false);
                if (expData.expDetail && expData.expDetail.length > 0)
                    setFirstSubexperienceId(expData.expDetail[0].id);
                else console.error('No subexperiences');
            })
            .catch((err) => {
                alert(err);
                history.replace('/home');
            });
    }, [expId]);

    // useEffect(() => {
    //   if (experiences && experiences[expIndex]) {
    //     setExperienceData(experiences[expIndex]);
    //     setLive(experiences[expIndex].status);
    //   }
    // }, [experiences]);

    const handleArchive = () => {
        // Alternar el valor de archived
        setArchived(!archived);
    };

    const handleSubmit = () => {
        // setAnchorEl(true);
        console.log('handleSubmit');
        console.log(experiences);
        const expStatus = {
            id: expId,
            // experiencerId: experiences[0].experiencerId,
            experiencerId: userInfo.experiencer.id,
            status: archived ? 2 : live ? 1 : 0,
        };
        ExperienceService.updateStatus(expId, expStatus)
            .then((res) => {
                console.log('RESPUESTA ====', res[0]);
                if (res[0]) {
                    setSnackBarMsg('Success');
                } else {
                    setSnackBarMsg('Servidor no disponible');
                }
                handleClick();
                history.push('/home');
            })
            .catch((e) => {
                setSnackBarMsg('oops algo salio mal');
                handleClick();
            });
    };

    const handleClick = () => {
        setOpen(true);
    };
    const handleCloseSb = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClose = () => {
        setAnchorEl(false);
    };

    const goLiveButton = () => {
        if (live) {
            return translate('editExperience.buttons.live');
        } else {
            return translate('editExperience.buttons.liveOff');
        }
    };

    const buttonClassName = live ? 'primary' : 'secondary';

    const handleTooltipOpen = () => {
        if (
            !live &&
            (!userInfo.email ||
                ((!userInfo.experiencer.mobilePhone ||
                    userInfo.experiencer.mobilePhone === '+') && // When deleting your phone number from your profile, the new value is "+"  instead of null or undefined
                    !userInfo.experiencer.officePhone))
        ) {
            setTooltipOpen(true);
        }
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const renderContent = () => {
        if (forbidden) {
            return null;
        }

        return (
            <Layout>
                {forbidden ? null : (
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Grid2
                                    container
                                    direction="column"
                                    spacing={1}
                                    className={classes.container}
                                >
                                    <Grid2>
                                        <Card
                                            variant="outlined"
                                            className={classes.card}
                                        >
                                            <CardHeader
                                                className={classes.optionCard}
                                                component={() => {
                                                    return (
                                                        <>
                                                            <Grid2
                                                                container
                                                                justifyContent="space-between"
                                                                spacing={1}
                                                                style={{
                                                                    padding: 20,
                                                                }}
                                                            >
                                                                <Grid2>
                                                                    <Typography variant="h3">
                                                                        {experienceData &&
                                                                            experienceData.name}
                                                                    </Typography>
                                                                </Grid2>
                                                                <Grid2>
                                                                    {!archived && (
                                                                        <Button
                                                                            variant="contained"
                                                                            color={
                                                                                buttonClassName
                                                                            }
                                                                            onMouseEnter={
                                                                                handleTooltipOpen
                                                                            }
                                                                            onMouseLeave={
                                                                                handleTooltipClose
                                                                            }
                                                                            onClick={() => {
                                                                                if (
                                                                                    !live &&
                                                                                    (!userInfo.email ||
                                                                                        ((!userInfo
                                                                                            .experiencer
                                                                                            .mobilePhone ||
                                                                                            userInfo
                                                                                                .experiencer
                                                                                                .mobilePhone ===
                                                                                                '+') && // When deleting your phone number from your profile, the new value is "+"  instead of null or undefined
                                                                                            !userInfo
                                                                                                .experiencer
                                                                                                .officePhone))
                                                                                ) {
                                                                                    // This will prevent the button from toggling the "live" state if the tooltip is shown
                                                                                    if (
                                                                                        !tooltipOpen
                                                                                    ) {
                                                                                        setTooltipOpen(
                                                                                            true
                                                                                        );
                                                                                    }
                                                                                } else {
                                                                                    setLive(
                                                                                        !live
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                title={translate(
                                                                                    'editExperience.buttons.missingInformation'
                                                                                )}
                                                                                open={
                                                                                    tooltipOpen
                                                                                }
                                                                                onClose={() =>
                                                                                    setTooltipOpen(
                                                                                        false
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {goLiveButton()}
                                                                                </span>
                                                                            </Tooltip>
                                                                        </Button>
                                                                    )}
                                                                    <Button
                                                                        variant="contained"
                                                                        color={
                                                                            archived
                                                                                ? 'secondary'
                                                                                : 'default'
                                                                        }
                                                                        onClick={
                                                                            handleArchive
                                                                        }
                                                                        style={{
                                                                            marginLeft:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        {archived
                                                                            ? translate(
                                                                                  'editExperience.buttons.archived'
                                                                              )
                                                                            : translate(
                                                                                  'editExperience.buttons.archive'
                                                                              )}
                                                                    </Button>
                                                                </Grid2>
                                                            </Grid2>
                                                        </>
                                                    );
                                                }}
                                                // title={

                                                // }

                                                // subheader={

                                                // }
                                            />
                                            <Divider />
                                            <CardContent>
                                                <Grid2
                                                    container
                                                    justifyContent="space-around"
                                                    alignItems="center"
                                                    spacing={2}
                                                    className={
                                                        classes.buttonsContainer
                                                    }
                                                >
                                                    <Grid2
                                                        size={{ xs: 6, sm: 3 }}
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:
                                                                    '#71ba6c',
                                                            }}
                                                            className={
                                                                classes.homeButton
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    `/experience/${expId}/profile`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    ProfileIcon
                                                                }
                                                                className={
                                                                    classes.buttonIcon
                                                                }
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                className={
                                                                    classes.buttonText
                                                                }
                                                            >
                                                                {translate(
                                                                    'editExperience.buttons.profile'
                                                                )}
                                                            </Typography>
                                                        </Button>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, sm: 3 }}
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:
                                                                    '#fb8f1d',
                                                            }}
                                                            className={
                                                                classes.homeButton
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    `/experience/scheduling/${expId}/${firstSubexperienceId}`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    ScheduleIcon
                                                                }
                                                                className={
                                                                    classes.buttonIcon
                                                                }
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                className={
                                                                    classes.buttonText
                                                                }
                                                            >
                                                                {translate(
                                                                    'editExperience.buttons.scheduling'
                                                                )}
                                                            </Typography>
                                                        </Button>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, sm: 3 }}
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:
                                                                    '#ffbb0c',
                                                            }}
                                                            className={
                                                                classes.homeButton
                                                            }
                                                            onClick={() => {
                                                                // history.push(
                                                                //     `/my-bookings/${expId}/${firstSubexperienceId}`
                                                                // )
                                                                const bkg = {
                                                                    id: expId,
                                                                    checked: true,
                                                                };
                                                                setFilteredExperiences(
                                                                    [bkg]
                                                                );
                                                                history.push(
                                                                    `/booking`
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    BookingIcon
                                                                }
                                                                className={
                                                                    classes.buttonIcon
                                                                }
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                className={
                                                                    classes.buttonText
                                                                }
                                                            >
                                                                {translate(
                                                                    'editExperience.buttons.bookings'
                                                                )}
                                                            </Typography>
                                                        </Button>
                                                    </Grid2>
                                                    <Grid2
                                                        size={{ xs: 6, sm: 3 }}
                                                        container
                                                        justifyContent="center"
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:
                                                                    '#9bc2d1',
                                                            }}
                                                            className={
                                                                classes.homeButton
                                                            }
                                                            onClick={() =>
                                                                history.push(
                                                                    `/experience/${expId}/resource`
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    ProfileIcon
                                                                }
                                                                className={
                                                                    classes.buttonIcon
                                                                }
                                                            />
                                                            <Typography
                                                                variant="button"
                                                                className={
                                                                    classes.buttonText
                                                                }
                                                            >
                                                                {translate(
                                                                    'editExperience.buttons.resource'
                                                                )}
                                                            </Typography>
                                                        </Button>
                                                    </Grid2>
                                                </Grid2>
                                            </CardContent>
                                            <SaveModal
                                                handleSubmit={handleSubmit}
                                                handleClose={handleClose}
                                                snakBarHandler={handleClick}
                                                anchorEl={anchorEl}
                                                saveForm={true}
                                                contentId={'saveForm'}
                                            />
                                            <SnackBar
                                                msg={snackBarMsg}
                                                handleClose={handleCloseSb}
                                                handleClick={handleClick}
                                                open={open}
                                            />
                                        </Card>
                                    </Grid2>
                                    <Grid2 container justifyContent="flex-end">
                                        <Grid2>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                // style={{ right: 30 }}
                                                onClick={() =>
                                                    setAnchorEl(true)
                                                }
                                            >
                                                {translate(
                                                    'editExperience.buttons.save'
                                                )}
                                            </Button>
                                        </Grid2>
                                    </Grid2>
                                </Grid2>
                            </form>
                        )}
                    />
                )}
            </Layout>
        );
    };

    return renderContent();
};

EditExperience.displayName = 'EditExperience';
