import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

export const useStyles = makeStyles((theme) => ({
    titleDialog: {
        marginBottom: '1rem',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '25rem',
        marginTop: '1rem',
    },
    fieldCustom: {
        padding: '0px',
        width: '100%',
        marginBottom: theme.spacing(2),
        fontSize: '1rem',
    },
    selectCustom: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '1.5rem',
    },
    createButton: {
        marginBottom: theme.spacing(1),
        backgroundColor: palette.utriper.green,
        fontSize: '0.8rem',
        color: 'white',
        '&:hover': {
            backgroundColor: palette.utriper.greendark,
        },
    },
    buttonContainer: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'flex-end',
    },
    iconButton: {},
}));
