import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormControl,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const ErrorCheckbox = withStyles({
  root: {
    color: '#f44336',
  },
})((props) => <Checkbox color="default" {...props} />);

const CheckboxComponent = ({
  checked,
  onChange  = () => { },
  labelComponent,
  hasError,
  errorMessage,
  ...props
}) => {
  const RenderedCheckbox = hasError ? <ErrorCheckbox /> : <Checkbox style={{color: "#2a81e8"}} />;

  return (
    <FormControl error={hasError}>
      <FormControlLabel
        control={RenderedCheckbox}
        onChange={(event) => {
          onChange(event.target.checked)
          if (props.onFieldChange) {props.onFieldChange(event.target.checked)};
          if (props.onTextFieldChange) {props.onTextFieldChange(event.target.checked)};
          props.input?.onChange(event.target.checked);
        }}
        checked={checked === undefined ? props.input.value : checked}
        label={labelComponent}
        {...props} ></FormControlLabel>
      {hasError ? (
        <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
          {errorMessage}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default CheckboxComponent;
