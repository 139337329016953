import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

export const ColorButton = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.scienceBlue,
        '&:hover': {
            backgroundColor: theme.palette.colors.scienceBlue,
        },
    },
}))(Button);

ColorButton.displayName = 'ColorButton';
