import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Grid2, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { useStyles } from './styles';
import React, { useState } from 'react';
import { useTranslation } from 'core/contexts/localization';

import {
    getImagesSrcArray as getMoodsImagesSrcArray,
    getTranslatedDescription as getMoodsTranslatedDescription,
    getTranslatedName as getMoodsTranslatedName,
    moods,
} from 'core/libs/import/Moods';

const moodsImg = getMoodsImagesSrcArray();

export const Modus = ({ ...props }) => {
    console.log('MOODS', props.moduses);
    console.log('experienceInfoTemp', props.experienceInfoTemp.modus);
    const moduses = props.moduses;
    const classes = useStyles();
    const { t: translate, i18n } = useTranslation();
    const i18nLang = i18n.language;

    const modusViewData = [];
    const [modusViewState, setModusViewState] = useState(modusViewData);
    moduses.map((modus) => {
        const modusId = modus.id;
        const modusState = false;
        // const modusState = props.experienceInfoTemp.modus[modus.id - 1]
        modusViewData.push({ id: modusId, status: modusState });
    });
    if (!(props.experienceInfoTemp.modus.length === 0)) {
        props.experienceInfoTemp.modus.map((elem) => {
            modusViewData.find((modus) => modus.id === elem.id).status = true;
            // modusViewData[elem.id].status = true;
        });
    }

    const [refreshModal, setRefreshModal] = useState(false);

    const focusHandler = (e) => {
        console.log('focusHandler', e);
        if (!e || e.length === 0) {
            return;
        }

        document.getElementById(e).style.transform = 'scale(1.05)';
    };

    const unFocusHandler = () => {
        let cardList = document.getElementsByName('cardMedia');
        for (let i = 0; cardList.length > i; i++) {
            cardList[i].style.transform = 'scale(1)';
        }
    };

    const handleInput = (index) => {
        const modus = moduses[index];
        const newModusViewState = [...modusViewState];

        newModusViewState.find(
            (modusState) => modusState.id === modus.id
        ).status = !newModusViewState.find(
            (modusState) => modusState.id === modus.id
        ).status;

        props.experienceInfoTemp.modus = [];
        newModusViewState.map((modusState) => {
            if (modusState.status) {
                props.experienceInfoTemp.modus.push({ id: modusState.id });
            }
        });

        setModusViewState(newModusViewState);
        setRefreshModal(!refreshModal);
    };

    const GreenCheckbox = withStyles({
        root: {
            color: '#2F80ED',
            '&$checked': {
                color: '#2F80ED',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <Grid2 className={classes.cardsWrapper}>
            {moduses.map((modus, index) => {
                return (
                    <Card
                        key={`${index}-card`}
                        id={`cardMedia-${index}`}
                        onMouseEnter={(e) => {
                            focusHandler(e.target.id);
                        }}
                        onMouseLeave={() => {
                            unFocusHandler();
                        }}
                        onClick={(e) =>
                            handleInput(parseFloat(e.target.id.split('-')[1]))
                        }
                        className={classes.smCard}
                    >
                        <CardActionArea
                            key={`${index}-CardActionArea`}
                            id={`cardMedia-${index}-action`}
                        >
                            <CardMedia
                                id={`cardMedia-${index}-media`}
                                name={`cardMedia`}
                                className={classes.smCardMedia}
                                // image={modus.image}
                                // image={require(`core/${modus.img_src}`)}
                                // image={img}
                                image={
                                    moodsImg.find(
                                        (elem) => elem.id === modus.id
                                    ).img_src
                                }
                            >
                                <div
                                    style={{
                                        marginTop: 'auto',
                                        marginBottom: 0,
                                        width: 'inherit',
                                    }}
                                    className={classes.cardDivContainer}
                                >
                                    <CardContent
                                        key={`${index}-card-content`}
                                        className={classes.cardContent}
                                        id={`cardMedia-${index}-content`}
                                    >
                                        <Typography
                                            key={`${index}-card-Typography-1`}
                                            id={`cardMedia-${index}-cardTitle`}
                                            gutterBottom
                                            variant="h5"
                                            style={{ fontWeight: 'bold' }}
                                            color="black"
                                            component="h2"
                                        >
                                            {/* {modus.name} */}
                                            {getMoodsTranslatedName(
                                                modus.id,
                                                i18nLang,
                                                moods
                                            )}
                                        </Typography>
                                        <Typography
                                            key={`${index}-card-Typography-2`}
                                            id={`cardMedia-${index}-cardText`}
                                            variant="body2"
                                            color="black"
                                            component="p"
                                        >
                                            {/* {modus.description} */}
                                            {getMoodsTranslatedDescription(
                                                modus.id,
                                                i18nLang,
                                                moods
                                            )}
                                        </Typography>
                                    </CardContent>
                                    <CardActions
                                        key={`${index}-CardActionArea2`}
                                        style={{ float: 'right' }}
                                        onMouseEnter={(e) => {
                                            focusHandler(e.target.id);
                                        }}
                                        id={`cardMedia-${index}-footer`}
                                    >
                                        <Typography
                                            key={`${index}-card-Typography-3`}
                                            id={`cardMedia-${index}-cardText2`}
                                            // style={{ color: '#2F80ED' }}
                                            color="black"
                                            variant="body2"
                                            component="label"
                                        >
                                            {translate('moduses.select')}
                                        </Typography>
                                        <GreenCheckbox
                                            key={`moduses-${index}-card-GreenCheckbox`}
                                            id={`cardMedia-${index}-checkBox`}
                                            color="#2F80ED"
                                            inputProps={{
                                                'aria-label':
                                                    'secondary checkbox',
                                            }}
                                            checked={
                                                modusViewState.find(
                                                    (mood) =>
                                                        mood.id == modus.id
                                                )?.status ?? false
                                            }
                                        />
                                    </CardActions>
                                </div>
                            </CardMedia>
                        </CardActionArea>
                    </Card>
                );
                // return await import("core/" + modus.img_src).then((img) => {
                //   return (
                //     <Card
                //       key={`${index}-card`}
                //       id={`cardMedia-${index}`}
                //       onMouseEnter={(e) => {
                //         focusHandler(e.target.id);
                //       }}
                //       onMouseLeave={() => {
                //         unFocusHandler();
                //       }}
                //       className={classes.smCard}>
                //       <CardActionArea
                //         key={`${index}-CardActionArea`}
                //         id={`cardMedia-${index}-action`}>
                //         <CardMedia
                //           id={`cardMedia-${index}-media`}
                //           name={`cardMedia`}
                //           className={classes.smCardMedia}
                //           // image={modus.image}
                //           // image={require(`core/${modus.img_src}`)}
                //           image={img}
                //         />
                //         <CardContent
                //           key={`${index}-card-content`}
                //           className={classes.cardContent}
                //           id={`cardMedia-${index}-content`}>
                //           <Typography
                //             key={`${index}-card-Typography-1`}
                //             id={`cardMedia-${index}-cardTitle`}
                //             gutterBottom
                //             variant="h5"
                //             component="h2">
                //             {/* {modus.name} */}
                //             {getMoodsTranslatedName(modus.id, i18nLang, moods)}
                //           </Typography>
                //           <Typography
                //             key={`${index}-card-Typography-2`}
                //             id={`cardMedia-${index}-cardText`}
                //             variant="body2"
                //             color="textSecondary"
                //             component="p">
                //             {/* {modus.description} */}
                //             {getMoodsTranslatedDescription(modus.id, i18nLang, moods)}
                //           </Typography>
                //         </CardContent>
                //       </CardActionArea>
                //       <CardActions
                //         key={`${index}-CardActionArea2`}
                //         style={{ float: 'right' }}
                //         onMouseEnter={(e) => {
                //           focusHandler(e.target.id);
                //         }}
                //         id={`cardMedia-${index}-footer`}>
                //         <Typography
                //           key={`${index}-card-Typography-3`}
                //           id={`cardMedia-${index}-cardText2`}
                //           style={{ color: '#2F80ED' }}
                //           variant="body2"
                //           component="label">
                //           {translate('moduses.select')}
                //         </Typography>
                //         <GreenCheckbox
                //           key={`moduses-${index}-card-GreenCheckbox`}
                //           id={`cardMedia-${index}-checkBox`}
                //           onClick={(e) =>
                //             handleInput(parseFloat(e.target.id.split('-')[1]))
                //           }
                //           color="#2F80ED"
                //           inputProps={{ 'aria-label': 'secondary checkbox' }}
                //           checked={modusViewState[index].status}
                //         />
                //       </CardActions>
                //     </Card>
                //   );
                // })
            })}
        </Grid2>
    );
};
