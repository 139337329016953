import {
    FormControl,
    Select,
    MenuItem,
    ListItemText,
    InputLabel,
    OutlinedInput,
} from '@mui/material';
import { useTranslation } from 'core/contexts/localization';

const ExperienceSelect = ({ experiences, onChange, value }) => {
    const { t: translate } = useTranslation();

    return (
        <FormControl variant="outlined" style={{ width: '20rem' }}>
            <style>
                {`
                      .MuiMenu-paper {
                          width: 20rem;
                          max-height: 20rem;
                      }
                  `}
            </style>
            <InputLabel>{translate('bookingForm.experience')}</InputLabel>
            <Select
                label={translate('bookingForm.experience')}
                value={value}
                style={{
                    width: '20rem',
                    color: 'black',
                }}
                MenuProps={{
                    getContentAnchorEl: () => null,
                    PaperProps: {
                        style: {
                            maxHeight: '20rem',
                            width: '20rem',
                            color: '#000',
                        },
                    },
                }}
            >
                {experiences.map((exp, index) => {
                    return (
                        <MenuItem
                            key={exp.id}
                            value={exp.id}
                            onClick={(event) => onChange(exp.id)}
                            style={{
                                whiteSpace: 'break-spaces',
                                color: 'black',
                            }}
                        >
                            <ListItemText
                                primary={exp.name}
                                style={{
                                    color: 'black',
                                    wordBreak: 'break-word',
                                }}
                            />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

ExperienceSelect.displayName = 'ExperienceSelect';

export default ExperienceSelect;
