import { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import axios from 'axios';
import { useCurrency } from 'core/contexts/currency';
import { TriperService } from 'core/api/services/triper';
import { useAuth } from 'core/contexts/auth';

const CurrencyInputs = () => {
    const { t: translate } = useTranslation();

    const [currencyType, setCurrencyType] = useState([]);

    const { currency, getCurrencyExchange, setCurrency } = useCurrency();

    useEffect(() => {
        const options = {
            method: 'GET',
            url: 'https://currency-exchange.p.rapidapi.com/listquotes',
            headers: {
                'x-rapidapi-key':
                    '6dd4a3edc8msh9f07d0593500b13p1af642jsnadd7939a04c9',
                'x-rapidapi-host': 'currency-exchange.p.rapidapi.com',
            },
        };

        axios
            .request(options)
            .then(function (response) {
                response.data.push('COP');
                response.data.push('ARS');
                setCurrencyType(response.data);
            })
            .catch(function (error) {
                console.error(error);
            });
    }, []);

    return (
        <>
            <FormControl variant="outlined">
                <InputLabel id="select-trip">
                    {translate('scheduleForm.currency')}
                </InputLabel>
                <Select
                    style={{ height: 55 }}
                    labelId="select-trip"
                    id="select-trip"
                    label={translate('scheduleForm.currency')}
                    variant="outlined"
                    defaultValue={currency}
                    value={currency}
                    // onChange={(e) => getCurrencyExchange(e.target.value)}>
                    // onChange={(e) => setCurrency(e.target.value)}>
                    onChange={async (e) => {
                        await TriperService.update({
                            currency: e.target.value.toUpperCase(),
                        });
                        setCurrency(e.target.value.toUpperCase());
                    }}
                >
                    {currencyType.map((value, index) => {
                        return (
                            <MenuItem
                                key={`currency-type-${index}`}
                                style={{ color: 'black' }}
                                value={value}
                            >
                                {value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </>
    );
};

const MoneyDropDown = () => {
    const handleSubmit = () => {};
    return (
        <div style={{ marginRight: '10px' }}>
            <Form
                key={'experience-info'}
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form key={'experience-info-form'} onSubmit={handleSubmit}>
                        <Field
                            autoFocus
                            name={'MoneyDropDown'}
                            component={CurrencyInputs}
                        />
                    </form>
                )}
            />
        </div>
    );
};

export default MoneyDropDown;
