import { useState, useCallback } from 'react';

import { useStyles } from './styles';

export const useLayout = () => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const toggleMenu = useCallback(() => setOpen((open) => !open), []);

    return {
        classes,
        open,
        toggleMenu,
    };
};
