import React, { useState } from 'react';
import { Grid2, Button, Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import { Form, Field } from 'react-final-form';
import { useStyles } from './styles';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import { ResourceService } from 'core/api/services/resource';
import { toast } from 'react-toastify';

const ResourceAdd = ({
    onClose,
    categorySeletected,
    onPostSuccess,
    ...props
}) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const [name, setName] = useState(categorySeletected?.name);
    const [id, _] = useState(categorySeletected?.id);

    const onFormSubmit = async (event) => {
        event.preventDefault();
        let methodCategory;

        const formData = {
            id,
            name,
        };

        if (categorySeletected && Object.keys(categorySeletected).length > 0) {
            methodCategory = 'updateCategory';
        } else {
            methodCategory = 'postCategory';
        }
        ResourceService[methodCategory](formData)
            .then((result) => {
                if (result) {
                    toast.success(translate('resource.resource_success'), {
                        position: 'top-right',
                        theme: 'colored',
                    });
                    onClose();
                    onPostSuccess();
                } else {
                    console.error('La respuesta no fue exitosa:', result);
                    toast.error(translate('resource.resource_error'), {
                        position: 'top-right',
                        theme: 'colored',
                    });
                }
            })
            .catch((error) => {
                console.error('Error al enviar el formulario:', error);
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            });
    };

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.category.create_category')}
            </Typography>
            <Divider />
            <Grid2>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{}}
                    render={({ handleSubmit, form }) => {
                        return (
                            <form
                                className={classes.formContainer}
                                onSubmit={onFormSubmit}
                            >
                                <Grid2>
                                    <TextInput
                                        input={{
                                            type: 'text',
                                            placeholder:
                                                translate('profile.name'),
                                            value: name,
                                            onChange: (e) =>
                                                setName(e.target.value),
                                        }}
                                        meta={{
                                            touched: true,
                                            error: '',
                                        }}
                                        ignoreError={false}
                                        className={classes.fieldCustom}
                                    />
                                </Grid2>
                                <Box className={classes.buttonContainer}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className={classes.createButton}
                                    >
                                        Guardar
                                    </Button>
                                </Box>
                            </form>
                        );
                    }}
                ></Form>
            </Grid2>
        </>
    );
};

export default ResourceAdd;
