import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useBookings } from 'core/contexts/booking';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'core/contexts/localization';
import TextInput from 'components/Input/Text';
import { useMediaQuery, Grid2 } from '@mui/material';
import PhoneNumber from 'components/Input/PhoneNumber';
import { useStyles } from './styles';

const AddBookingForm = (props) => {
    const classes = useStyles();

    const initialStateValues = {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        start: '',
        end: '',
        mobilePhone: undefined,
        quantity: undefined,
        date: '',
        price: 1,
        status: 1,
        currency: 'COP',
        repeat: false,
        mobilePhoneCountryCode: '',
        paidPrice: 1,
        arrivalTime: undefined,
        isArrivalTimeInRange: false,
        arrivalTimeFormatted: new Date(),
    };

    useEffect(() => {
        console.log('props.from is: ', props?.from);
    }, [props]);

    const hasSlots = props.slots?.length > 0 ?? false;
    const { t: translate } = useTranslation();
    const [values, setValues] = useState(initialStateValues);

    const required = (value) => {
        // console.error('value===', value);
        return value ? undefined : translate('form.required');
    };
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const { getBookings, bookings, create, addReservation } = useBookings();

    let expNum = window.location.href.split('/');
    const expIndex = parseFloat(expNum[expNum.length - 1]) - 1;

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // console.log("NAME==", name, "VALUE==", value)
        setValues({
            ...values,
            [name]: value,
            // TODO: es necesario?
            id: expIndex,
            start: props.start,
            end: props.end,
        });
    };

    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const handleSubmit = async (e) => {
        let timeOfArrival;
        if (values.displayArrivalTime) {
            console.log('we have arrivalTime');
            timeOfArrival = values.displayArrivalTime;
        } else if (selectedSlotId !== null) {
            console.log('we DO NOT have arrivalTime');
            timeOfArrival = props.slots.find(
                (slot) => slot.id === selectedSlotId
            ).time;
        }

        // All we do in the next lines is prepare the arrival time in the format the server expects us to send it
        // A well-formed request has a
        // 'date' property --> 2022-08-20T09:30:00

        const data = {
            email: values.email,
            firstName: values.firstname,
            lastName: values.lastname,
            quantity: 1,
            status: 1,
            mobilePhone: values.mobilePhone,
            mobilePhoneCountryCode: values.mobilePhoneCountryCode,
            price: values.price,
            paidPrice: values.paidPrice,
            date: '2024-03-06T06:30:00',
        };
        const success = await addReservation(
            props.expId,
            props.subExpId,
            props.from,
            props.to,
            data
        );
        props.onSave();
        props.onDismiss();
    };

    const validatePositiveFloatInput = (value) => {
        // const regex = /^((([1-9][0-9]*)|([0]))[.])?[0-9]*$/;
        const regex = /^([0-9]+[.])?[0-9]*$/;
        return regex.test(value);
    };
    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={{ ...values }}
            render={({ handleSubmit, values }) => (
                <form
                    onSubmit={handleSubmit}
                    noValidate
                    style={isSmall ? { width: '80vw' } : { width: 'auto' }}
                >
                    <Grid2 container spacing={1}>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                            <Field
                                required
                                validate={required}
                                className={classes.inputClass}
                                variant="outlined"
                                label={translate('bookingForm.firstname')}
                                name="firstname"
                                value={values.firstName}
                                onTextFieldChange={handleInputChange}
                                component={TextInput}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                            <Field
                                required
                                validate={required}
                                className={classes.inputClass}
                                variant="outlined"
                                label={translate('bookingForm.lastname')}
                                name="lastname"
                                value={values.lastName}
                                onTextFieldChange={handleInputChange}
                                component={TextInput}
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2 container spacing={1}>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                            <Field
                                required
                                validate={(email) => {
                                    if (!email) {
                                        return translate('form.required');
                                    }
                                    if (!validateEmail(email)) {
                                        return translate('form.invalid.email');
                                    }
                                    return undefined;
                                }}
                                className={classes.inputClass}
                                variant="outlined"
                                label="Email"
                                name="email"
                                value={values.email}
                                onTextFieldChange={handleInputChange}
                                component={TextInput}
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 6 }}>
                            <Field
                                id="phone"
                                onFieldChange={(value, country) => {
                                    const auxValues = {
                                        ...values,
                                        mobilePhone: value,
                                        mobilePhoneCountryCode: country,
                                    };
                                    setValues(auxValues);
                                }}
                                className={classes.inputClass}
                                name="mobilePhone"
                                component={PhoneNumber}
                                hintText={translate('profile.phone')}
                                label={translate('bookingForm.phone') + ' *'}
                                initialCountry={'us'}
                            />
                        </Grid2>
                    </Grid2>

                    <Grid2
                        container
                        justifyContent="flex-end"
                        spacing={2}
                        style={{ paddingTop: '15px' }}
                    >
                        <Grid2>
                            <Button
                                onClick={(event) => {
                                    props.onDismiss();
                                }}
                                type="button"
                                variant="contained"
                                color="secondary"
                            >
                                {translate('trip.create.buttons.cancel')}
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {translate('trip.create.buttons.save')}
                            </Button>
                        </Grid2>
                    </Grid2>
                </form>
            )}
        />
    );
};
export default AddBookingForm;
