import Layout from '../../components/Layout';
import ResourcePageComponent from './componenets/ResourcePage';
import { Grid2 } from '@mui/material';

import { useStyles } from './styles';

const ResourcePage = () => {
    const classes = useStyles();

    return (
        <Layout contentClassName={classes.content}>
            <Grid2>
                <ResourcePageComponent />
            </Grid2>
        </Layout>
    );
};

ResourcePage.displayName = 'ResourcePage';

export default ResourcePage;
