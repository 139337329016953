import HomeIcon from 'assets/icons/menu/home.svg';
import ProfileIcon from 'assets/icons/menu/profile.svg';
import BookingsIcon from 'assets/icons/menu/bookings.svg';
// import MyTripsIcon from 'assets/icons/menu/myTrips.svg';
// import CalendarIcon from 'assets/icons/menu/calendar.svg';
// import BudgetIcon from 'assets/icons/menu/budget.svg';
// import ArrowForwardIos from '@mui/icons-material';
import { ROUTES } from 'core/routing/routes';
import resourceIcon from 'assets/icons/menu/resource.svg';

// export const triperMenu = [
//   {
//     name: 'layout.menu.home',
//     link: ROUTES.HOME,
//     icon: HomeIcon,
//   },
//   {
//     name: 'layout.menu.profile',
//     link: ROUTES.PROFILE,
//     icon: ProfileIcon,
//   },
//   {
//     name: 'layout.menu.my_trips',
//     link: ROUTES.TRIP.HOME,
//     icon: MyTripsIcon,
//   },
//   {
//     name: 'layout.menu.calendar',
//     link: ROUTES.ITINERARY,
//     icon: CalendarIcon,
//   },
//   {
//     name: 'layout.menu.budget',
//     link: ROUTES.BUDGET,
//     icon: BudgetIcon,
//   },
// ];

export const experiencerMenu = [
    {
        name: 'layout.menu.home',
        link: '/home',
        icon: HomeIcon,
    },
    {
        name: 'layout.menu.profile',
        link: '/profile',
        icon: ProfileIcon,
    },
    {
        name: 'layout.menu.resources',
        link: '/resource',
        icon: resourceIcon,
    },
    {
        name: 'layout.menu.mybookings',
        link: '/booking',
        icon: BookingsIcon,
    },
];
