import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    smTitle: {
        fontFamily: 'DM Sans',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '23px',
        display: 'flex',
        alignItems: 'center',
    },
    smFooter: {
        height: '36px',
    },
    smCancelButton: {
        float: 'right',
        paddingTop: '9px',
        background: '#C4C4C4',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    smSaveButton: {
        float: 'right',
        paddingTop: '9px',
        marginLeft: '14px',
        background: '#2F80ED',
        border: 'none !important',
        borderRadius: '6px',
        color: 'white',
        fontFamily: 'Mina',
        fontWeight: '700',
        width: '99px',
        lineHeight: '1.75',
    },
    cardsWrapper: {
        display: 'inline-block',
        width: 1260,
        [theme.breakpoints.down('lg')]: {
            width: 932,
        },
        [theme.breakpoints.down('md')]: {
            width: 722,
        },
        [theme.breakpoints.down('sm')]: {
            width: 272,
        },
    },
    smCard: {
        width: '370px',
        height: '320px',
        margin: '15px 20px',
        display: 'inline-block',
        [theme.breakpoints.down('lg')]: {
            width: '306px',
            height: '320px',
            margin: '15px 2.333px',
            display: 'inline-block',
        },
        [theme.breakpoints.down('md')]: {
            width: '355px',
            height: '320px',
            margin: '15px 2.999px',
            display: 'inline-block',
        },
        [theme.breakpoints.down('sm')]: {
            width: '272px',
            height: 'auto',
            margin: '15px 0px',
            display: 'inline-block',
        },
    },
    smCardMedia: {
        transition: '0.5s',
        height: '320px',
        display: 'flex',
        width: 'inherit',
        [theme.breakpoints.down('sm')]: {
            minWidth: '272px',
            height: 'auto',
        },
    },
    cardContent: {
        minWidth: '280px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '272px',
        },
    },
    cardDivContainer: {
        // background: `linear-gradient(141deg, rgba(128,128,128,1) 0%, rgba(128,128,128,0.7595413165266106) 40%, rgba(255,255,255,1) 100%)`
        background: 'rgba(190,190,190, 0.8)',
    },
}));
