import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid2, Paper, Typography, Box, Button } from '@mui/material';
import ResourceExperienceCard from './ResourceExperienceCard';
import CategoryExperienceCard from './CategoryExperienceCard';
import { useStyles } from './styles';
import AlertDialog from 'core/libs/core-ui/components/Dialog';

import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';
import { ExperienceResourceService } from 'core/api/services/ExperienceInfo/expResource';
import CategoryToExperienceAddModal from './CategoryToExperienceAddModal';
import ResourceToExperienceAddModal from './ResourceToExperienceAddModal';

const ResourceExperiencePageComponent = ({ ...props }) => {
    const classes = useStyles();
    const { id } = useParams();
    const expIndex = parseInt(id);

    const [experience, setExperience] = useState(null);
    const [resources, setResources] = useState(null);
    const [resourceCategories, setResourceCategories] = useState(null);
    const [openResourceModalAdd, setOpenResourceModalAdd] = useState(false);
    const [openCategoryModalAdd, setOpenCategoryModalAdd] = useState(false);
    const closeModalResource = () => {
        getResources();
        setOpenResourceModalAdd(false);
    };
    const closeModalCategory = () => {
        getResourceCategories();
        setOpenCategoryModalAdd(false);
    };

    useEffect(() => {
        if (expIndex) {
            // Load experience
            ExperienceInfoService.get(expIndex).then((expData) => {
                setExperience(expData);
                getResources();
                getResourceCategories();
            });
        }
    }, []);

    const getResourceCategories = () => {
        ExperienceResourceService.getResourceCategoryExperience(expIndex).then((resourceCategoriesData) => {
            setResourceCategories(resourceCategoriesData.resourceCategories);
        });
    };

    const getResources = () => {
        ExperienceResourceService.getResourceExperience(expIndex).then((resourcesData) => {
            setResources(resourcesData.resources);
        });
    };

    return (
        <>
            <Grid2 container>
                <Box sx={{ padding: '2rem' }}>
                    <Typography variant="h3" gutterBottom>
                        {experience?.name}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent={'center'} // Temporary until categories is ready
                    flexDirection={{ xs: 'column', md: 'row' }}
                    width="100%"
                >
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Paper style={{ margin: 8 }}>
                            <Grid2 container alignItems="center" spacing={1} style={{ padding: '1rem' }}>
                                <Grid2>
                                    <Typography variant="h2">Recursos</Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Agrega recursos a la experiencia
                                    </Typography>
                                </Grid2>
                                <Grid2 style={{ marginLeft: 'auto' }}>
                                    <Button onClick={() => setOpenResourceModalAdd(true)} className={classes.buttonAdd} variant="contained">
                                        Agregar
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Grid2 container>
                                {resources?.map((resource) => (
                                    <ResourceExperienceCard resource={resource} onDelete={(id) => ExperienceResourceService.deleteResource(expIndex, id).then(() => getResources())} onUpdate={(resource) => ExperienceResourceService.updateResource(expIndex, resource).then(() => getResources())} />
                                ))}
                            </Grid2>
                        </Paper>
                    </Grid2>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <Paper style={{ margin: 8 }}>
                            <Grid2 container alignItems="center" spacing={1} style={{ padding: '1rem' }}>
                                <Grid2>
                                    <Typography variant="h2">Categorias</Typography>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Agrega categorias de recursos a la experiencia
                                    </Typography>
                                </Grid2>
                                <Grid2 style={{ marginLeft: 'auto' }}>
                                    <Button onClick={() => setOpenCategoryModalAdd(true)} className={classes.buttonAdd} variant="contained">
                                        Agregar
                                    </Button>
                                </Grid2>
                            </Grid2>
                            <Grid2 container>
                                {resourceCategories?.map((resourceCategory) => (
                                    <CategoryExperienceCard resourceCategory={resourceCategory} onDelete={(id) => ExperienceResourceService.deleteResourceCategory(expIndex, id).then(() => getResourceCategories())} onUpdate={(resourceCategory) => ExperienceResourceService.updateResourceCategory(expIndex, resourceCategory).then(() => getResourceCategories())} />
                                ))}
                            </Grid2>
                        </Paper>
                    </Grid2>
                </Box>
            </Grid2>
            <AlertDialog
                open={openResourceModalAdd}
                handleClose={closeModalResource}
                dialogTitle=" "
                hideActions={true}
                className={classes.modalContent}
                labelBack="Salir"
                labelNext="Crear"
                dialogContent={
                    <>
                        <ResourceToExperienceAddModal experienceId={expIndex} onClose={closeModalResource} handleSubmit={ExperienceResourceService.addResource} getResources={ExperienceResourceService.getResources} />
                    </>
                }
            />
            <AlertDialog
                open={openCategoryModalAdd}
                handleClose={closeModalCategory}
                dialogTitle=" "
                hideActions={true}
                className={classes.modalContent}
                labelBack="Salir"
                labelNext="Crear"
                dialogContent={
                    <>
                        <CategoryToExperienceAddModal experienceId={expIndex} onClose={closeModalCategory} handleSubmit={ExperienceResourceService.addResourceCategory} getResources={ExperienceResourceService.getResourceCategories} />
                    </>
                }
            />
        </>
    );
};

export default ResourceExperiencePageComponent;
