import { useAuth } from 'core/contexts/auth';
import { useExperiencers } from 'core/contexts/experiencer';
import { useStyles } from './styles';

export const useAppBar = (isHome) => {
    const classes = useStyles();

    const { role, setRole, userInfo } = useAuth();

    const { become } = useExperiencers();

    const setUserRole = (selectedRole) => {
        setRole(selectedRole);
    };

    return {
        classes,
        role,
        setRole: setUserRole,
        // showBecomeButton,
        onBecomeClick: become,
    };
};
