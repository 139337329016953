import { fetch, Method } from 'core/api/fetch';

const getList = async ({ entity, search, input }) => {
    try {
        const url = `/api/v1.1.1/search/${entity}?${search}=${input}`;
        const response = await fetch(url, {
            method: Method.GET,
            authenticated: true,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        console.error(`Error fetching ${entity} with search term ${search}:`, error);
        throw error;
    }
};

export const EntityService = {
    getList,
};
