import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: 'center',
        display: 'block',
    },
    subtitle: {
        textAlign: 'start',
        display: 'block',
        margin: '6px',
        fontSize: '18px',
    },
    form: {
        width: '30%',
    },
    percentage: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.3rem',
    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0.2rem 0',
    },
    checkbox: {
        marginLeft: '5%',
    },
}));
