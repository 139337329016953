import React, { useState, useEffect, useCallback, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Grid2, Typography, Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'core/contexts/localization';
import AddIcon from 'assets/icons/home/add.svg';

import InputRow from './InputRow';

const GreenCheckbox = withStyles({
    root: {
        color: '#2F80ED',
        '&$checked': {
            color: '#2F80ED',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CancelationPolicies = ({
    experienceInfoTemp,
    originalExperienceInfo,
}) => {
    const { t: translate } = useTranslation();
    // console.log('originalExperienceInfo: ', originalExperienceInfo)
    // console.log('experienceInfoTemp: ', experienceInfoTemp)

    const [nonRefundable, setNonRefundable] = useState(
        originalExperienceInfo.cancelPolicies.nonRefundable
    );
    const [additionals, setAdditionals] = useState([]);
    const refNonRefundable = useRef({ item: nonRefundable });
    const refAdditionals = useRef({ item: additionals });

    // useEffect(() => {
    //   console.log('nonRefundable changed!: ', nonRefundable)
    // }, [nonRefundable])

    useEffect(() => {
        let internalAdditionals = [
            { id: -1, time: null, percentage: null, days: null, hours: null },
        ];
        if (originalExperienceInfo.cancelPolicies.additionals.length > 0) {
            internalAdditionals =
                originalExperienceInfo.cancelPolicies.additionals.map(
                    (additional) => {
                        return {
                            ...additional,
                            days: Math.floor(additional.time / 1440),
                            hours: Math.floor((additional.time % 1440) / 60),
                        };
                    }
                );
        }
        // console.log('internalAdditionals: ', internalAdditionals)
        setAdditionals(internalAdditionals);
    }, []);

    useEffect(() => {
        experienceInfoTemp.cancelPolicies.nonRefundable = nonRefundable;
        // console.log('--------------------------------------')
        // console.log("dependency additionals changed!");
        // console.log('additionals: ', additionals)
        // console.log('refAdditionals.current.item: ', refAdditionals.current.item)
        // console.log('--------------------------------------')
        experienceInfoTemp.cancelPolicies.additionals = additionals
            .filter((additional) => {
                return (
                    additional.percentage !== null &&
                    ((additional.days !== null &&
                        additional.days.length === undefined) ||
                        (additional.hours !== null &&
                            additional.hours.length === undefined)) &&
                    additional.percentage.length === undefined
                );
            })
            .map((additional) => {
                return {
                    id: additional.id,
                    time: additional.days * 1440 + additional.hours * 60,
                    percentage: additional.percentage,
                };
            });
    }, [additionals, nonRefundable]);

    const handleAdditionalFieldChange = (field, value, index) => {
        const newAdditionals = [...additionals];
        newAdditionals[index][field] = value;
        refAdditionals.current.item = newAdditionals;
        setAdditionals(newAdditionals);
    };

    const handleAdditionalRemoval = (index) => {
        const newAdditionals = [...additionals];
        newAdditionals.splice(index, 1);
        refAdditionals.current.item = newAdditionals;
        setAdditionals(newAdditionals);
    };

    return (
        <Grid2 style={{ maxWidth: 1024, margin: '10px 0px 10px 0px' }}>
            <style>
                {`
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      `}
            </style>
            <div>
                <GreenCheckbox
                    onClick={(e) => {
                        refNonRefundable.current.item = !nonRefundable;
                        // if setting nonRefundable to false, autocomplete additionals with pre-set default policies for refund policies
                        if (nonRefundable) {
                            // console.log('autocompleting additionals...')
                            const defaultAdditionals = [];
                            // more than 3 days / 72hs
                            defaultAdditionals.push({
                                id: -1,
                                time: null,
                                percentage: 90,
                                days: 3,
                                hours: 0,
                            });
                            // between 1 & 3 days / 24hs - 72hs
                            defaultAdditionals.push({
                                id: -1,
                                time: null,
                                percentage: 50,
                                days: 1,
                                hours: 0,
                            });
                            // less than 1 day / 24 hs
                            defaultAdditionals.push({
                                id: -1,
                                time: null,
                                percentage: 0,
                                days: 0,
                                hours: 0,
                            });
                            setAdditionals(defaultAdditionals);
                        }
                        setNonRefundable(!nonRefundable);
                    }}
                    color="#2F80ED"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    checked={nonRefundable}
                />
                <Typography variant="body2" component="label">
                    {translate(
                        'experienceProfile.saveModal.cancelationPolicies.non'
                    )}
                </Typography>
            </div>
            {additionals.map((additional, index) => {
                return (
                    <InputRow
                        style={{ margin: '0.5rem 0', padding: '0.5rem' }}
                        disabled={nonRefundable}
                        key={index}
                        index={index}
                        initialPercentage={additional.percentage}
                        onChangePercentage={(value) => {
                            handleAdditionalFieldChange(
                                'percentage',
                                value,
                                index
                            );
                        }}
                        initialDays={additional.days}
                        onDaysChange={(value) => {
                            handleAdditionalFieldChange('days', value, index);
                        }}
                        initialHours={additional.hours}
                        onHoursChange={(value) => {
                            handleAdditionalFieldChange('hours', value, index);
                        }}
                        onRemove={() => {
                            handleAdditionalRemoval(index);
                        }}
                    />
                );
            })}
            <Fab
                disabled={nonRefundable}
                size="small"
                className="bg-blue-300"
                onClick={() => {
                    const auxAdditionals = [...additionals];
                    auxAdditionals.push({
                        id: -1,
                        time: null,
                        percentage: null,
                        days: null,
                        hours: null,
                    });
                    setAdditionals(auxAdditionals);
                }}
                // aria-label={translate('trip.view.destinations.add')}
            >
                <img src={AddIcon} width={24} alt="" />
            </Fab>
        </Grid2>
    );
};
