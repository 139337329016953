import React, { useState } from 'react';
import { Grid2, Button, Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import { Form } from 'react-final-form';
import { useStyles } from './styles';
import { toast } from 'react-toastify';
import { LookUpResourceCategory } from 'core/libs/core-ui/components/Lookup/Entities';
import { EntityService } from 'core/api/services/lookUp';

const CategoryToExperienceAddModal = ({ experienceId, getResources, handleSubmit, onClose, ...props }) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const [categorySelected, setCategorySelected] = useState('');

    const onFormSubmit = async (values) => {
        const newData = [
            {
                resourceId: categorySelected,
                resourceCategoryId: categorySelected,
                notificationEmail: false,
                notificationWhatsapp: false,
            },
        ];
        try {
            const result = await handleSubmit(experienceId, newData);
            if (result) {
                toast.success(translate('resource.resource_success'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            } else {
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
            onClose();
        } catch (error) {
            console.error(translate('resource.resource_error'), error);
            toast.error(translate('resource.resource_error'), {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    const required = (value) => {
        return value ? undefined : 'Required';
    };

    const handleSelectionChange = (selectedValue) => {
        setCategorySelected(selectedValue);
    };

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.add_category')}
            </Typography>
            <Divider />
            <Grid2>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{}}
                    render={({ input, meta, handleSubmit, valid, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid2>
                                <LookUpResourceCategory value={categorySelected} entityService={EntityService} onChange={(newValue) => handleSelectionChange(newValue)} />

                                {/* <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Email Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setEmailNotificationCheckBox(value);
                                    }}
                                    disabled={!categorySelected?.email}
                                />

                                <Field
                                    name="checkboxFieldName"
                                    component={CheckboxComponent}
                                    type="checkbox"
                                    labelComponent={translate(
                                        'Whatsapp Notificacion'
                                    )}
                                    onChange={(value) => {
                                        setwhatsappNotification(value);
                                    }}
                                    disabled
                                /> */}

                                <Box className={classes.buttonContainer}>
                                    <Button disabled={!valid} type="submit" className={classes.createButton}>
                                        {translate('trip.create.buttons.add_co_triper')}
                                    </Button>
                                </Box>
                            </Grid2>
                        </form>
                    )}
                ></Form>
            </Grid2>
        </>
    );
};

export default CategoryToExperienceAddModal;
