import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: theme.spacing(),
        color: theme.palette.colors.mineShaft,
        boxShadow:
            '0px 0px 8px rgba(0, 0, 0, 0.12), inset 0px 1px 1px rgba(255, 255, 255, 0.12)',
    },
    icon: {
        color: theme.palette.colors.mineShaft,
    },
    dotInactive: {
        borderRadius: '50%',
        color: '#A0C4FF',
        fontSize: '15px',
        marginLeft: '4%',
    },
    dotActive: {
        borderRadius: '50%',
        color: '#4DBC58',
        fontSize: '15px',
        marginLeft: '4%',
    },
    dotArchived: {
        borderRadius: '50%',
        color: 'lightGrey',
        fontSize: '15px',
        marginLeft: '4%',
    },
    statusText: {
        fontSize: '12.8px',
    },
}));
