import { useState, useEffect, useRef } from 'react';
import { Grid2, TextField } from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import { useStyles } from './styles';

const Timing = ({ experienceInfoTemp }) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const initialTime = {
        days: Math.floor((experienceInfoTemp.anticipation ?? 0) / 1440),
        hours: Math.floor(((experienceInfoTemp.anticipation ?? 0) % 1440) / 60),
        minutes: ((experienceInfoTemp.anticipation ?? 0) % 1440) % 60 ?? 0,
    };

    const didMountRef = useRef(false);

    const [time, setTime] = useState(initialTime);

    const updateAnticipation = () => {
        let { days, hours, minutes } = time;
        days = isNaN(days) ? 0 : days;
        hours = isNaN(hours) ? 0 : hours;
        minutes = isNaN(minutes) ? 0 : minutes;
        const totalMinutes = days * 1440 + hours * 60 + minutes;
        experienceInfoTemp.anticipation = totalMinutes;
    };

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        } else {
            updateAnticipation();
        }
        return () => {};
    }, [time]);

    return (
        <div>
            <span className={classes.text}>
                {translate('experienceProfile.saveModal.timing.subtitle1')}
            </span>
            <Grid2 container className={classes.timeSelectors}>
                <Grid2 size={4}>
                    <TextField
                        label={translate('duration.days')}
                        variant="outlined"
                        value={time.days}
                        onChange={(e) => {
                            let value = 0;
                            const targetValue = parseInt(e.target.value);
                            if (targetValue < 0) {
                                value = 0;
                            } else if (targetValue > 365) {
                                value = 365;
                            } else {
                                value = targetValue;
                            }
                            setTime({ ...time, days: value });
                        }}
                        type="number"
                        inputProps={{
                            min: 0,
                            max: 999,
                            step: 1,
                            'aria-label': 'booking-percentage',
                        }}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <TextField
                        label={translate('duration.hours')}
                        variant="outlined"
                        value={time.hours}
                        onChange={(e) => {
                            let value = 0;
                            const targetValue = parseInt(e.target.value);
                            if (targetValue < 0) {
                                value = 0;
                            } else if (targetValue > 999) {
                                value = 999;
                            } else {
                                value = parseInt(targetValue);
                            }
                            setTime({ ...time, hours: value });
                        }}
                        type="number"
                        inputProps={{
                            min: 0,
                            max: 999,
                            step: 1,
                            'aria-label': 'booking-percentage',
                        }}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <TextField
                        label={translate('duration.minutes')}
                        variant="outlined"
                        value={time.minutes}
                        onChange={(e) => {
                            let value = 0;
                            const targetValue = parseInt(e.target.value);
                            if (targetValue < 0) {
                                value = 0;
                            } else if (targetValue > 999) {
                                value = 999;
                            } else {
                                value = targetValue;
                            }
                            setTime({ ...time, minutes: value });
                        }}
                        type="number"
                        inputProps={{
                            min: 0,
                            max: 999,
                            step: 1,
                            'aria-label': 'booking-percentage',
                        }}
                    />
                </Grid2>
            </Grid2>
            <span className={classes.text} style={{ marginBottom: '1rem' }}>
                {translate('experienceProfile.saveModal.timing.subtitle2')}
            </span>
        </div>
    );
};

export default Timing;
