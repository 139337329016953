import React from 'react';
import {
    IconButton,
    Container,
    Grid2,
    Paper,
    Typography,
    TextField,
    FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';

const CategoryExperienceCard = ({
    resourceCategory,
    onUpdate,
    onDelete,
    ...props
}) => {
    return (
        <Container>
            <Paper
                style={{
                    padding: 16,
                    marginTop: '1rem',
                    marginBottom: '1rem',
                }}
            >
                <Grid2 container alignItems="flex-start" spacing={2}>
                    <Grid2 size={12} container justifyContent="flex-end">
                        <IconButton
                            aria-label="delete"
                            onClick={() => onDelete(resourceCategory.id)}
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 8 }}>
                        <Grid2 container spacing={2}>
                            <Typography variant="h2">
                                {resourceCategory?.name}
                            </Typography>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }}>
                        <Grid2
                            container
                            spacing={1}
                            style={{ flexDirection: 'column' }}
                            display="flex"
                        >
                            <Grid2 size={{ xs: 12, sm: 8 }}>
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox name="emailNotification" />
                                    }
                                    label="Todos disponiles"
                                /> */}
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 8 }}>
                                <CheckboxComponent
                                    labelComponent={'Email Notification'}
                                    checked={
                                        resourceCategory?.notificationEmail ||
                                        false
                                    }
                                    onChange={(value) =>
                                        onUpdate({
                                            ...resourceCategory,
                                            notificationEmail: value,
                                        })
                                    }
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 8 }}>
                                <CheckboxComponent
                                    labelComponent={'Whatsapp Notification'}
                                    checked={
                                        resourceCategory?.notificationWhatsapp ||
                                        false
                                    }
                                    disabled
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Paper>
        </Container>
    );
};

export default CategoryExperienceCard;
