import makeStyles from '@mui/styles/makeStyles';
import palette from 'core/libs/core-ui/theme/colors-palette';

export const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
    container: {
        padding: '60px 40px',
        [theme.breakpoints.down('md')]: {
            padding: '60px 10px',
        },
    },
    containerTable: {
        width: '100%',
    },
    header: {
        alignItems: 'center',
    },
    title: {
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(2)} ${theme.spacing(
                2
            )} 0px ${theme.spacing(2)}`,
        },
    },
    selectContainer: {
        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
        },
        [theme.breakpoints.down('md')]: {
            padding: `0px ${theme.spacing(2)}`,
        },
    },
    experiencesTitle: {
        margin: `${theme.spacing(2)} 0px`,
        [theme.breakpoints.down('md')]: {
            padding: `0px ${theme.spacing(2)}`,
        },
    },
    globalBookingsTitle: {
        width: '100%',
        padding: '3rem 2rem',
    },
    globalBookingsFilter: {
        margin: '0 2rem',
        display: 'inline',
        marginBottom: '1rem',
        width: '100%',
    },
    globalBookingsSearchBoxContainer: {
        display: 'inline',
        [theme.breakpoints.down('md')]: {
            margin: '0 2rem',
            display: 'flex',
            justifyContent: 'space-between',
            width: '20rem',
        },
    },
    globalBookingsSearchBox: {
        [theme.breakpoints.down('md')]: {
            margin: '0.5rem 0 0 0',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: theme.spacing(2),
        outline: 'none',
        borderRadius: '4px',
    },
    input: {
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '1rem',
        color: '#000',
    },
    buttonAdd: {
        float: 'right',
        cursor: 'pointer',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: palette.utriper.green,
        '&:hover': {
            backgroundColor: palette.utriper.darkgreen,
        },
    },
}));
