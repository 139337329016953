import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'core/contexts/localization';
import { Grid2 } from '@mui/material';
import { green } from '@mui/material/colors';
import withStyles from '@mui/styles/withStyles';
import { InputBase } from '@mui/material';
import { useState } from 'react';
import { useStyles } from './styles';

export const Includes = ({ ...props }) => {
    const includeData = props.experienceInfoTemp.include;
    const includeOtherData = includeData.otherDescription;
    const initalOtherCheck = () => {
        if (
            includeOtherData === null ||
            includeOtherData === '' ||
            includeOtherData === undefined
        ) {
            return false;
        } else {
            return true;
        }
    };
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const [refreshModal, setRefreshModal] = useState(false);
    const [otherCheck, setOtherCheck] = useState(() => initalOtherCheck());
    const enviromentModalIndex = [
        {
            transportation: translate(
                'experienceProfile.saveModal.include.Transport'
            ),
        },
        { ticket: translate('experienceProfile.saveModal.include.Ticket') },
        { food: translate('experienceProfile.saveModal.include.Food') },
        { beverage: translate('experienceProfile.saveModal.include.Beverage') },
        {
            photography: translate(
                'experienceProfile.saveModal.include.Photography'
            ),
        },
        {
            accommodation: translate(
                'experienceProfile.saveModal.include.Accommodation'
            ),
        },
    ];
    const handleInput = (input) => {
        includeData[input] = !includeData[input];
        setRefreshModal(!refreshModal);
    };
    const handleInputChangeOther = (input) => {
        includeData.otherDescription = input;
        if (input !== '') {
            setOtherCheck(true);
        } else {
            setOtherCheck(false);
        }
    };

    const GreenCheckbox = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <Grid2 style={{ maxWidth: 1024, margin: '20px 20px 20px 20px' }}>
            {enviromentModalIndex.map((entry, index) => {
                const objKey = Object.keys(entry);
                return (
                    <CardActions
                        key={`${objKey}-${index}-cardActions`}
                        className={classes.cardAction}
                    >
                        <GreenCheckbox
                            key={`${objKey}-${index}-GreenCheckbox`}
                            id={`${objKey}-${index}-checkBox`}
                            checked={includeData[objKey]}
                            onClick={(e) =>
                                handleInput(e.target.id.split('-')[0])
                            }
                            color="#2F80ED"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        <Typography
                            id={`cardMedia-${index}-cardText2`}
                            key={`${entry}-${index}-Typography`}
                            style={{ color: '#2F80ED' }}
                            variant="body2"
                            component="label"
                        >
                            {entry[objKey]}
                        </Typography>
                    </CardActions>
                );
            })}
            <CardActions className={classes.cardAction}>
                <GreenCheckbox
                    checked={otherCheck}
                    color="#2F80ED"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <InputBase
                    name="experienceSubTitle-alphaNumeric-subtitle"
                    placeholder={translate('experienceProfile.saveModal.other')}
                    defaultValue={includeData.otherDescription}
                    inputProps={{ 'aria-label': 'naked' }}
                    onChange={(e) => handleInputChangeOther(e.target.value)}
                />
            </CardActions>
        </Grid2>
    );
};
