import React, { useState } from 'react';
import {
    TextField,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { CalendarToday, Close } from '@mui/icons-material';
import DateRangePicker from '../DateRangePicker';

function DateRangePickerWithInput({ label, value, onChange, ...props }) {
    const [showCalendar, setShowCalendar] = useState(false);

    const handleCalendarToggle = () => {
        setShowCalendar(!showCalendar);
    };

    const handleDateChange = (date) => {
        onChange(date);
        setShowCalendar(false);
    };

    return (
        (<div style={{ position: 'relative', ...props.style }}>
            <TextField
                className={props.classes}
                style={props?.inputProps?.style}
                id="input-with-icon-textfield"
                label={label}
                value={value}
                fullWidth
                variant="outlined"
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                {value && (
                                    <>
                                    <IconButton onClick={handleCalendarToggle} size="large">
                                        <CalendarToday />
                                     </IconButton>
                                    <IconButton onClick={() => onChange({})} size="large">
                                        <Close />
                                    </IconButton>
                                    </>
                                )}
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                {!value && (
                                <IconButton onClick={handleCalendarToggle} size="large">
                                    <CalendarToday />
                                </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    }
                }}
            />
            {showCalendar && (
                <div style={{
                        position: 'absolute',
                        zIndex: 1000,
                    }}>
                    <DateRangePicker
                        label={label}
                        value={value}
                        onChange={handleDateChange}
                        sx={{
                            '& .MuiPickersDay-root': {
                                color: '#7d7987',
                                fontWeight: '500 !important',
                                fontSize: '12px !important',
                                background: 'transparent',
                            },
                            '& .MuiDayCalendar-weekDayLabel': {
                                color: '#7d7987',
                                fontWeight: '500 !important',
                                fontSize: '12px !important',
                                background: 'transparent'
                            },
                            '& .Mui-selected': {
                                color: '#fff',
                                borderRadius: '18px',
                                backgroundColor: '#363740 !important',
                                '&:hover': {
                                  backgroundColor: '#363740',
                                }
                          },
                        }}
                        slotProps={{
                            actionBar: {
                              actions: []
                            },
                            toolbar: {
                              sx: {display: 'none'}
                            },
                            calendarHeader: {
                              disabled: true,
                            },
                          }}
                    />
                </div>
            )}
        </div>)
    );
}

export default DateRangePickerWithInput;
