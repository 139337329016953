import { useEffect } from 'react';
import WebFont from 'webfontloader';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider as MuiPickerProvider } from '@mui/x-date-pickers';

import theme from 'core/theme';
import Router from 'core/routing';

import { AuthProvider } from 'core/contexts/auth';
import { TripsProvider } from 'core/contexts/trips';
import { ModusesProvider } from 'core/contexts/modus';
import { LocationsProvider } from 'core/contexts/location';
import { CoTripersProvider } from 'core/contexts/coTriper';
import { ExperiencersProvider } from 'core/contexts/experiencer';
import { TripersProvider } from 'core/contexts/triper';
import { ExperiencesProvider } from './core/contexts/experience';
import { ScheduleProvider } from './core/contexts/schedule';
import { BookingProvider } from './core/contexts/booking';
import { CurrencyProvider } from 'core/contexts/currency';
import { LocalizationProvider } from 'core/contexts/localization';
import { PaymentProvider } from 'core/contexts/payment';
import getDatepickerLocale from 'core/locales/getDatepickerLocale';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StripeProvider } from 'core/contexts/stripe';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import 'antd/dist/antd.css';

const App = () => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Mina', 'Mina:bold'],
            },
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MuiPickerProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={getDatepickerLocale()}
                >
                    <LocalizationProvider>
                        <AuthProvider>
                            <StripeProvider>
                                <CurrencyProvider>
                                    <TripsProvider>
                                        <ModusesProvider>
                                            <LocationsProvider>
                                                <CoTripersProvider>
                                                    <ExperiencersProvider>
                                                        <TripersProvider>
                                                            <ExperiencesProvider>
                                                                <ScheduleProvider>
                                                                    <BookingProvider>
                                                                        <PaymentProvider>
                                                                            <Router />
                                                                        </PaymentProvider>
                                                                    </BookingProvider>
                                                                </ScheduleProvider>
                                                            </ExperiencesProvider>
                                                        </TripersProvider>
                                                    </ExperiencersProvider>
                                                </CoTripersProvider>
                                            </LocationsProvider>
                                        </ModusesProvider>
                                    </TripsProvider>
                                </CurrencyProvider>
                            </StripeProvider>
                        </AuthProvider>
                        <ToastContainer />
                    </LocalizationProvider>
                </MuiPickerProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

App.displayName = 'App';

export default App;
