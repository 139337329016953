import { Grid2, IconButton, Button, TextField } from '@mui/material';
import { GooglePlacesAutocomplete } from './components/placesAutocomplete';
import { useTranslation } from 'core/contexts/localization';

export const Location = ({ ...props }) => {
    const { t: translate } = useTranslation();

    return (
        <>
            <Grid2
                container
                itemAlign={'center'}
                spacing={2}
                size={12}
                style={{ height: '100px', alignItems: 'center' }}
            >
                <Grid2
                    itemAlign={'center'}
                    style={{ textAlign: 'center' }}
                    size={3}
                >
                    <label id="slide-modal-video-label">
                        {translate(
                            'experienceProfile.saveModal.location.title'
                        )}
                    </label>
                </Grid2>
                <Grid2 itemAlign={'center'} size={9}>
                    <GooglePlacesAutocomplete
                        parseDataToSet={props.parseDataToSet}
                    />
                </Grid2>
            </Grid2>
        </>
    );
};
