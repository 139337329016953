import React, { useState, useEffect } from 'react';
import { Grid2, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';

import CapacityIcon from 'assets/icons/capacity.svg';
import CodeIcon from 'assets/icons/code.svg';

import FullCalendar from 'core/libs/core-ui/components/Fullcalendar';

import { useTranslation } from 'core/contexts/localization';
import { useAuth } from 'core/contexts/auth';

import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';
import { ExperiencerService } from 'core/api/services/experiencer';

import { formatMoney } from 'core/packages/format';

import { useCalendarPage } from './hook';
import { useStyles } from './styles';
import { DateTime } from 'luxon';
import { Lens } from '@mui/icons-material';

const colorPalette = ['#1E88E5', '#43A047', '#F4511E', '#FB8C00', '#3949AB', '#8E24AA', '#D81B60', '#00897B', '#5E35B1', '#3949AB', '#039BE5', '#FDD835', '#E53935', '#6D4C41', '#757575', '#8D6E63', '#78909C', '#546E7A', '#26A69A', '#FF7043', '#FFCA28', '#8EACBB', '#AB47BC', '#FFB300', '#C62828', '#AD1457', '#00796B', '#BDBDBD', '#F57F17', '#37474F'];

const CalendarPage = ({ expId, view, ...props }) => {
    const history = useHistory();
    const classes = useStyles();
    const { t: translate } = useTranslation();

    const {
        date: { value, onChange },
        calendar: { start, end, range, ref, onSelect, onDismiss, onSave, tz, setTz },
        popOver: { show, position, setOpen },
        isSmall,
        // onEventClick,
    } = useCalendarPage();

    const { getUserInfoSync } = useAuth();

    const [subExpId, setSelection] = useState(0);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [calendarRange, setCalendarRange] = useState({});
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        if (calendarRange && subExpId && expId.length > 0) {
            ExperiencerService.getBookingsCalendar({
                id: expId.map((exp) => exp.id),
                range: calendarRange,
                view,
            }).then((res) => {
                if (res.length === 1) {
                    setBookings(res[0]);
                } else {
                    setBookings(res.flat());
                }
            });
        } else {
            setBookings([]);
        }
    }, [calendarRange, subExpId, view]);

    useEffect(() => {
        if (expId) {
            setSelection(expId.map((exp) => exp.expDetailId));
        }
    }, [expId]);

    useEffect(() => {
        if (bookings) {
            const allExpsDIds = [...new Set(bookings.map((booking) => booking.experienceDetailId))];

            const expColorMap = allExpsDIds.reduce((acc, expId, index) => {
                acc[expId] = colorPalette[index % colorPalette.length];
                return acc;
            }, {});

            if (view === 'slotView') {
                setCalendarEvents(
                    bookings
                        ?.map((booking) => [
                            {
                                extendedProps: {
                                    price: booking.price,
                                    currency: booking.currency,
                                    capacity: booking.capacity,
                                    scheduleId: booking.scheduleId,
                                    ocupancy: booking.ocupancy,
                                    availability: booking.availability,
                                    expDetailId: booking.experienceDetailId,
                                    privateSlot: booking.privateSlot,
                                    expId: expId.find((exp) => exp.expDetailId === booking.experienceDetailId)?.id,
                                    expName: expId.find((exp) => exp.expDetailId === booking.experienceDetailId)?.name,
                                },
                                start: booking.start,
                                end: booking.end,
                                color: expColorMap[booking.experienceDetailId],
                            },
                        ])
                        ?.map((booking) => booking[0])
                );
            } else {
                setCalendarEvents(
                    bookings
                        ?.map((booking) => [
                            {
                                extendedProps: {
                                    id: booking.id,
                                    name: booking.name,
                                    quantity: booking.quantity,
                                    expDetailId: booking.experienceDetailId,
                                    expId: expId.find((exp) => exp.expDetailId === booking.experienceDetailId)?.id,
                                    expName: expId.find((exp) => exp.expDetailId === booking.experienceDetailId)?.name,
                                    code: booking.code,
                                },
                                start: booking.dateUTC,
                                end: DateTime.fromISO(booking.dateUTC)
                                    .plus({ minutes: booking?.duration || 60 })
                                    .toISO(),
                                color: expColorMap[booking.experienceDetailId],
                            },
                        ])
                        ?.map((booking) => booking[0])
                );
            }
        }
    }, [bookings]);

    function renderEventContent(eventInfo) {
        return (
            <div
                style={{
                    padding: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                }}
            >
                {view === 'slotView' ? (
                    <>
                        <div>
                            <Lens
                                style={{
                                    borderRadius: '50%',
                                    fontSize: '16px',
                                    border: '2px solid white',
                                    color: eventInfo.event.extendedProps?.availability < 25.0 ? 'red' : eventInfo.event.extendedProps?.availability < 50.0 ? '#FB8F1D' : eventInfo.event.extendedProps?.availability < 75.0 ? '#FFBB0C' : 'green',
                                }}
                            />
                        </div>
                        <div>
                            <b>{eventInfo.timeText}</b>
                        </div>
                        <div>
                            <b className="d-flex">
                                <span className="mr-2">{eventInfo.event.extendedProps?.currency}</span>
                                <span>{formatMoney(eventInfo.event.extendedProps?.price)}</span>
                            </b>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <img alt="" src={CapacityIcon} width={20} style={{ marginRight: 5, aspectRatio: '1 / 1' }} />
                            <b>
                                {eventInfo.event.extendedProps?.ocupancy ? `${eventInfo.event.extendedProps?.ocupancy}/` : ``}
                                {eventInfo.event.extendedProps?.capacity} {eventInfo.event.extendedProps?.privateSlot ? 'priv' : ''}
                            </b>
                        </div>
                    </>
                ) : (
                    <>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <img alt="" src={CodeIcon} width={16} style={{ marginRight: 5, aspectRatio: '1 / 1' }} />
                            <span>{eventInfo.event.extendedProps?.code}</span>
                        </div>
                        <div>
                            <span>{eventInfo.event.extendedProps?.name}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                            <img alt="" src={CapacityIcon} width={20} style={{ marginRight: 5, aspectRatio: '1 / 1' }} />
                            <b>{eventInfo.event.extendedProps?.quantity}</b>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            {view === 'slotView' && (
                <Grid2 container justifyContent="center" style={{ marginBottom: '25px' }}>
                    <div className={classes.availabilityInfo}>
                        <span
                            className="bold text-18"
                            style={{
                                fontWeight: 'bold !important',
                                fontSize: '18px !important',
                            }}
                        >
                            {translate('calendar.availability')}
                        </span>
                        <div>
                            <Chip className={classes.chip} label={`-${25} %`} style={{ backgroundColor: 'red' }} />
                            <Chip className={classes.chip} label={`${25} % - ${50} %`} style={{ backgroundColor: '#FB8F1D' }} />
                            <Chip className={classes.chip} label={`${50} % - ${75} %`} style={{ backgroundColor: '#FFBB0C' }} />
                            <Chip className={classes.chip} label={`+ ${75} %`} style={{ backgroundColor: 'green' }} />
                        </div>
                    </div>
                </Grid2>
            )}
            <Grid2 container>
                <FullCalendar
                    useCalendarPage={useCalendarPage}
                    schedules={calendarEvents}
                    renderEventContent={renderEventContent}
                    setCalendarRange={setCalendarRange}
                    value={value}
                    onChange={onChange}
                    title={''}
                    tz={tz}
                    setTz={setTz}
                    start={start}
                    end={end}
                    range={range}
                    ref={ref}
                    onSelect={onSelect}
                    onDismiss={onDismiss}
                    isSmall={isSmall}
                    showCreateEventeButton={false}
                    showExperiencesList={true}
                    listTitle={translate('calendar.viewCalendar.listTitle')}
                    colorPalette={colorPalette}
                    onEventClick={(event) => {
                        const { startStr, endStr } = event.event;
                        if (view === 'slotView') {
                            history.push({
                                pathname: `/booking/${startStr.substring(0, startStr.lastIndexOf('T'))}/${event.event.extendedProps.expId}/${event.event.extendedProps.expDetailId}`,
                                state: {
                                    scheduleId: event.event._def.extendedProps.scheduleId,
                                    start: startStr,
                                    end: endStr,
                                    formattedFrom: startStr.substring(0, startStr.lastIndexOf('T')),
                                },
                            });
                        } else {
                            history.push(`/booking/${event.event.extendedProps.id}`);
                        }
                    }}
                />
            </Grid2>
        </>
    );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;
