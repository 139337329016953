import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexWrap: 'nowrap',
            alignItems: 'center',
        },
    },
    avatar: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        border: `3px solid ${theme.palette.secondary.dark}`,
    },
    textContainer: {
        padding: `0px ${theme.spacing(2)}`,
        [theme.breakpoints.down('md')]: {
            padding: `${theme.spacing(2)} 0px`,
        },
    },
    text: {
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    editButton: {
        position: 'absolute',
        right: 0,
        top: -theme.spacing(),
    },
}));
