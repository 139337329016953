import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    modal: {
        padding: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
    },
    subtitle: {
        display: 'flex',
        marginTop: '8px',
    },
    messageBody: {
        height: '200px',
        borderRadius: '4px',
        marginBlock: '8px',
        padding: '4px',
    },
}));
