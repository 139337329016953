import React, { useMemo, useState, useEffect } from 'react';
import {
    Grid2,
    Typography,
    Hidden,
    Button,
    useMediaQuery,
    DialogContent,
    IconButton,
    Select,
    MenuItem,
    FormControl,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Field } from 'react-final-form';
import SaveIcon from '@mui/icons-material/Save';
import Layout from 'components/Layout';
import AddIcon from 'assets/icons/home/add.svg';
import EditIcon from 'assets/icons/edit.svg';
import { useStyles } from './styles';
import { useTranslation } from 'core/contexts/localization';
import { useBookings } from 'core/contexts/booking';
import { useAuth } from 'core/contexts/auth';
import { formatDate, formatMoney, formatHour } from 'core/packages/format';
import AddBooking from '../AddBoking';
import TimePicker from 'components/Input/TimePicker';
// import { BookingStatus } from 'core/api/services/booking';
import { ExperienceInfoService } from 'core/api/services/ExperienceInfo/experienceInfo';
import { ScheduleService } from 'core/api/services/schedule';

import BasicTable from 'core/libs/core-ui/components/Table';
import { dateTimeFormatted } from 'core/libs/utils/dateTimeUtils';
import { TimeFormattedUtils } from 'core/libs/core-ui/utils/dateTimeFormatted';
import { MoneyFormattedUtils } from 'core/libs/core-ui/utils/moneyFormatted';
import BookingStatus from 'core/libs/status/BookingStatus/BookingStatus';

import { ROUTES } from 'core/routing/routes';

const getAvailableMinutes = (startMinute, endMinute = 59) => {
    const minutes = [];
    for (let i = startMinute; i <= endMinute; i++) {
        minutes.push(i);
    }
    return minutes;
};

export const getHoursAndMinutesAvailablePerHour = (
    startHour,
    startMinute,
    endHour,
    endMinute
) => {
    const hoursAvailable = [];
    const minutesAvailablePerHour = [];

    if (startHour == endHour) {
        minutesAvailablePerHour.push(
            getAvailableMinutes(startMinute, endMinute)
        );
        hoursAvailable.push(startHour);
    } else if (endHour - startHour == 1) {
        minutesAvailablePerHour.push(getAvailableMinutes(startMinute));
        hoursAvailable.push(startHour);
        minutesAvailablePerHour.push(getAvailableMinutes(0, endMinute));
        hoursAvailable.push(endHour);
    } else {
        minutesAvailablePerHour.push(getAvailableMinutes(startMinute));
        hoursAvailable.push(startHour);
        for (let h = startHour + 1; h < endHour; h++) {
            hoursAvailable.push(h);
            minutesAvailablePerHour.push(getAvailableMinutes(0));
        }
        minutesAvailablePerHour.push(getAvailableMinutes(0, endMinute));
        hoursAvailable.push(endHour);
    }

    return {
        hours: hoursAvailable,
        minutes: minutesAvailablePerHour,
    };
};

const BookingDetailsPage = (props) => {
    console.log(props);
    const { expId, subExpId } = props.match.params;

    const location = useLocation();

    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    console.log('urlSearchParams', location.state);
    const startDate = location.formattedFrom;

    const history = useHistory();

    const classes = useStyles();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const { t: translate } = useTranslation();

    const { getUserInfoSync } = useAuth();

    const [experienceData, setExperienceData] = useState({
        title: '',
        description: '',
    });

    const {
        bookingReservations,
        updateReservation,
        getReservations,
        resetReservations,
    } = useBookings();

    const [showModal, setShowModal] = useState(false);
    const [bookingData, setBookingData] = useState([]);
    const [slots, setSlots] = useState([]);

    const [showCancelReasonModal, setShowCancelReasonModal] = useState(false);
    const [cancelBookingTriperData, setCancelBookingTriperData] =
        useState(null);
    const [cancelReasonText, setCancelReasonText] = useState('');

    const [availableTime, setAvailableTime] = useState([]);

    const ITEMS_PER_PAGE = 10;
    const [page, setPage] = useState(1);

    const getIndex = (index) => {
        return (page - 1) * ITEMS_PER_PAGE + index;
    };

    const columns = [
        {
            label: translate('bookingForm.code'),
            val: 'code',
        },
        {
            label: 'Triper',
            val: 'triper',
        },
        {
            label: translate('bookingForm.phone'),
            val: 'phoneNumber',
        },
        {
            label: 'Email',
            val: 'email',
        },
        {
            label: translate('bookingForm.arrivalTime'),
            val: 'timeOfArrivalString',
        },
        {
            label: translate('bookingForm.tickets'),
            val: 'tickets',
        },
        {
            label: translate('bookingForm.price'),
            val: 'priceFormatted',
        },
        {
            label: translate('bookingForm.paidPrice'),
            val: 'paidPriceFormatted',
        },
        {
            label: translate('bookingForm.toBePaidPrice'),
            val: 'toBePaidPriceFormatted',
        },
        {
            label: translate('bookingForm.status'),
            val: 'statusString',
        },
    ];

    useEffect(() => {
        // reset data
        resetReservations();
        ExperienceInfoService.get(expId)
            .then(async (expData) => {
                const userData = await getUserInfoSync();
                if (
                    !userData.experiencer ||
                    !userData.experiencer.id ||
                    expData.experiencerId != userData.experiencer.id
                ) {
                    throw new Error(
                        'Cannot view another user experience calendar!'
                    );
                }
                setExperienceData({
                    title: expData.name,
                    description: expData.expDetail[0].description1,
                });
            })
            .catch((err) => {
                alert(err);
                history.replace('/home');
            });
        getReservations(
            expId,
            subExpId,
            location.state.start,
            location.state.end
        );
    }, [subExpId, expId, location.state]);

    useEffect(() => {
        setBookingData(bookingReservations);
    }, [bookingReservations]);

    // const resetCancelStates = () => {
    //     setShowCancelReasonModal(false);
    //     setCancelReasonText('');
    //     setCancelBookingTriperData(null);
    // };

    // const getTime = (ISODate) => {
    //     const date = new Date(ISODate);
    //     let hours = date.getUTCHours();
    //     hours = hours < 10 ? `0${hours}` : hours;
    //     let minutes = date.getMinutes();
    //     minutes = minutes < 10 ? `0${minutes}` : minutes;
    //     return `${hours}:${minutes}`;
    // };

    // const isValidTime = (selectedDate) => {
    //     const date = new Date(selectedDate);
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const startDate = new Date(location.state.start);
    //     const endDate = new Date(location.state.end);
    //     const startHours = startDate.getUTCHours();
    //     const startMinutes = startDate.getMinutes();
    //     const endHours = endDate.getUTCHours();
    //     const endMinutes = endDate.getMinutes();
    //     console.log(
    //         'startHours',
    //         startHours,
    //         'startMinutes',
    //         startMinutes,
    //         'endHours',
    //         endHours,
    //         'endMinutes',
    //         endMinutes
    //     );
    //     console.log('hours', hours, 'minutes', minutes, selectedDate);
    //     if (hours < startHours || hours > endHours) {
    //         return false;
    //     }
    //     if (hours === startHours && minutes < startMinutes) {
    //         return false;
    //     }
    //     if (hours === endHours && minutes > endMinutes) {
    //         return false;
    //     }
    //     return true;
    // };

    const onClick = (event, id) => {
        history.push(`/booking/${id}`);
    };

    const prettifyBookings = (bookings = []) => {
        return bookings.map((booking) => {
            return {
                ...booking,
                statusString: BookingStatus.getLabel(booking.statusId),
                // originString: BookingOrigin.getLabel(booking.originId),
                // dateFormatted: DateFormattedUtils(booking.date),
                codeFormatted:
                    booking.code.length > 6
                        ? booking?.code?.slice(0, 6)
                        : booking.code,
                priceFormatted: `${booking.currency} ${MoneyFormattedUtils(
                    booking.totalPrice
                )}`,
                paidPriceFormatted: `${booking.currency} ${MoneyFormattedUtils(
                    booking.paidPrice
                )}`,
                toBePaidPriceFormatted: `${
                    booking.currency
                } ${MoneyFormattedUtils(booking.toBePaidPrice)}`,
                timeOfArrivalString: TimeFormattedUtils(
                    booking.timeOfArrivalFormatted,
                    null,
                    {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                        timeZone: booking.timezone || 'UTC',
                    }
                ),
            };
        });
    };

    return (
        <Layout contentClassName={classes.content}>
            <div className={classes.container}>
                <Grid2 container justifyContent="space-between">
                    <Grid2>
                        <h1>
                            <strong>{experienceData.title}</strong>
                        </h1>
                        <h3 style={{ marginTop: '-0.5rem' }}>
                            {formatDate(location.state.start)}
                        </h3>
                    </Grid2>
                    <Grid2>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => history.push(ROUTES.BOOKINGS.NEW)}
                            startIcon={
                                <img
                                    alt=""
                                    src={AddIcon}
                                    width={20}
                                    className="mr-2"
                                />
                            }
                        >
                            <Hidden lgDown>
                                {translate('calendar.add_booking')}
                            </Hidden>
                        </Button>
                    </Grid2>
                </Grid2>

                <BasicTable
                    onClick={onClick}
                    pagination={{ pageSize: ITEMS_PER_PAGE }}
                    columns={columns}
                    values={prettifyBookings(bookingReservations)}
                />
            </div>
        </Layout>
    );
};

export default BookingDetailsPage;
