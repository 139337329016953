import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

import { requiredUrl } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },

    marginIcon: {
        marginRight: theme.spacing(1),
    },
    spacingItem: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(0),
    },
}));

export const CustomMultiSelectLink = ({ input, label, disabled }) => {
    const classes = useStyles();
    const [internalValue, setInternalValue] = React.useState('');
    const [savedValues, setSavedValues] = React.useState([]);
    const [showError, setShowError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState();

    /**TODO mejorar evento de borrado de datos cuando se resetea el form */
    React.useEffect(() => {
        if (!input.value) {
            setSavedValues([]);
        }
    }, [input.value]);

    React.useEffect(() => {
        if (!input.value && !savedValues.length) {
            setInternalValue('');
        }
    }, [input.value, savedValues]);

    const onChangeInput = (event) => {
        input.onChange([]);
        setShowError(false);
        setErrorMsg('');
        setInternalValue(event.target.value);
    };

    const handleChange = () => {
        const errorMsg = requiredUrl(internalValue);
        if (errorMsg) {
            setShowError(true);
            setErrorMsg(errorMsg);
            return;
        }

        input.onChange([...savedValues, internalValue]);
        setSavedValues([...savedValues, internalValue]);
        setInternalValue('');
        setShowError(false);
        setErrorMsg('');
    };
    const onDelete = (position) => {
        const filteredValues = savedValues.filter(
            (element, index) => index !== position
        );
        setSavedValues(filteredValues);
        input.onChange(filteredValues);
    };

    const handleKeyDown = (e) => {
        const enterKey = 13;
        if (e.which === enterKey) {
            handleChange();
        }
    };
    return (
        <FormControl variant="outlined" error={showError ? showError : false}>
            <InputLabel htmlFor={input.name} disabled={disabled || false}>
                {label}
            </InputLabel>
            <OutlinedInput
                id={input.name}
                name={input.name}
                type={'text'}
                value={internalValue}
                onChange={(event) => onChangeInput(event)}
                onKeyDown={handleKeyDown}
                error={showError}
                disabled={disabled || false}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            className={classes.marginIcon}
                            aria-label={label || ''}
                            onClick={handleChange}
                            disabled={disabled}
                            edge="end"
                            size="large"
                        >
                            <AddCircleOutlineOutlined />
                        </IconButton>
                    </InputAdornment>
                }
                sx={{ width: 70 }}
            />
            {showError && (
                <FormHelperText id="component-error-text">
                    {errorMsg}
                </FormHelperText>
            )}
            <List dense={true}>
                {savedValues.map((e, index) => {
                    return (
                        <ListItem key={index} className={classes.spacingItem}>
                            <ListItemText secondary={<Link to={e}>{e}</Link>} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => onDelete(index)}
                                    edge="end"
                                    aria-label="delete"
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        </FormControl>
    );
};
