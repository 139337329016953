import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    content: {
        padding: theme.spacing(4),
    },
    text: {
        marginTop: theme.spacing(2),
    },
    marginLeft: {
        marginLeft: theme.spacing(2),
    },
}));
