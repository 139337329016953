import Layout from 'components/Layout';
// import { CircularProgress, useMediaQuery } from '@mui/material';
// import ResourcePageComponent from './componenets/ResourcePage';
import { Grid2 } from '@mui/material';

import ResourceExperiencePageComponent from '..';

const ResourceExperiencePage = () => {
    return (
        <Layout>
            <Grid2>
                <ResourceExperiencePageComponent />
            </Grid2>
        </Layout>
    );
};

ResourceExperiencePage.displayName = 'ResourceExperiencePage';

export default ResourceExperiencePage;
