import React from 'react';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';

import {
    Grid2,
    Button,
    Box,
    Typography,
    Divider,
    FormControlLabel,
} from '@mui/material';

import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import PhoneNumber from 'core/libs/core-ui/components/Input/PhoneNumber';
import Checkbox from 'core/libs/core-ui/components/Input/Checkbox';

import { useTranslation } from 'core/contexts/localization';

import { ResourceService } from 'core/api/services/resource';

import { useStyles } from './styles';

const ResourceAdd = ({
    openModalType,
    onClose,
    resourceSelected,
    categoriesData,
    onPostSuccess,
    ...props
}) => {
    const { t: translate } = useTranslation();
    const classes = useStyles();

    const onFormSubmit = async (values) => {
        const copValues = {
            mobilePhone: null,
            email: null,
            ...values,
        };
        let methodResource;
        if (resourceSelected && Object.keys(resourceSelected).length > 0) {
            methodResource = 'updateResource';
        } else {
            methodResource = 'postResource';
        }
        try {
            const result = await ResourceService[methodResource](copValues);
            if (result) {
                toast.success(translate('resource.resource_success'), {
                    position: 'top-right',
                    theme: 'colored',
                });
                onClose();
                onPostSuccess();
            } else {
                console.error(translate('resource.resource_error'), result);
                toast.error(translate('resource.resource_error'), {
                    position: 'top-right',
                    theme: 'colored',
                });
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
            toast.error(translate('resource.resource_error'), {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };
    const required = (value) => {
        return value ? undefined : 'Required';
    };
    const validateInt = (value) => {
        return !value || parseInt(value) ? undefined : 'Invalid Quantity';
    };
    const validateEmail = (value) =>
        !value ||
        (value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
            ? undefined
            : 'Invalid email address';

    const validatePhone = (value) =>
        !value || (value && /^(?:(?:\+|00)88|01)?\d{11}$/i.test(value))
            ? undefined
            : 'Invalid phone number';

    return (
        <>
            <Typography variant="h3" className={classes.titleDialog}>
                {translate('resource.add_resource')}
            </Typography>
            <Divider />
            <Grid2>
                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{
                        ...resourceSelected,
                    }}
                    render={({
                        input,
                        meta,
                        handleSubmit,
                        valid,
                        pristine,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <Grid2>
                                <Field
                                    id="name"
                                    className={classes.fieldCustom}
                                    name="name"
                                    component={TextInput}
                                    label={translate('resource.name_resource')}
                                    validate={required}
                                />
                                <Field
                                    id="email"
                                    className={classes.fieldCustom}
                                    name="email"
                                    component={TextInput}
                                    label={translate('resource.email_resource')}
                                    validate={validateEmail}
                                    allowNull
                                />
                                <Field
                                    id="phone"
                                    className={classes.phoneNumber}
                                    name="mobilePhone"
                                    component={PhoneNumber}
                                    hintText={translate('profile.phone')}
                                    label={translate('profile.phone')}
                                    validateField={validatePhone}
                                />
                                <Field
                                    name="resourceCategoryId"
                                    component={SelectComponent}
                                    label={translate('resource.type_resource')}
                                    menuItemProps={{
                                        style: {
                                            color: 'black',
                                            display: 'flex',
                                            alignItems: 'center',
                                        },
                                    }}
                                    onFieldChange={(event) => {
                                        const selectedType =
                                            event.target?.value || 0;
                                        if (selectedType === 0) {
                                            openModalType();
                                        }
                                    }}
                                    values={categoriesData}
                                    onEdit={(selectedOption) => {
                                        openModalType(selectedOption);
                                    }}
                                    labelId="resourceCategoryId"
                                    validate={required}
                                />
                                <Field
                                    style={{ marginTop: '0.8rem' }}
                                    variant="outlined"
                                    label={translate('resource.capacity')}
                                    name="capacity"
                                    component={TextInput}
                                    validate={validateInt}
                                />
                                <Grid2 container>
                                    <Grid2 size={6}>
                                        <Field
                                            style={{ marginTop: '0.8rem' }}
                                            variant="outlined"
                                            labelComponent={translate(
                                                'resource.unlimitedCapacity'
                                            )}
                                            color="primary"
                                            name="unlimitedCapacity"
                                            component={Checkbox}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Field
                                            style={{ marginTop: '0.8rem' }}
                                            variant="outlined"
                                            labelComponent={translate(
                                                'resource.privateResource'
                                            )}
                                            color="primary"
                                            name="privateResource"
                                            component={Checkbox}
                                        />
                                    </Grid2>
                                </Grid2>

                                <Box className={classes.buttonContainer}>
                                    <Button
                                        disabled={!valid || pristine}
                                        type="submit"
                                        className={classes.createButton}
                                    >
                                        Guardar
                                    </Button>
                                </Box>
                            </Grid2>
                        </form>
                    )}
                ></Form>
            </Grid2>
        </>
    );
};

export default ResourceAdd;
