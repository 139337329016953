import React from 'react';
import {
    Modal,
    Container,
    Grid2,
    Typography,
    Paper,
    Hidden,
    Button,
} from '@mui/material';
import { useTranslation } from 'core/contexts/localization';
import TermsAndConditionsSignUp from 'modules/TermsAndConditions/TermsAndConditionsSignUp';
import CloseIcon from '@mui/icons-material/Close';

const TermsAndConditions = ({ open, onClose, onClick }) => {
    const { t: translate } = useTranslation();

    // FIXME: modularizar en cuanto sea posible, se deberia crear un modal generico que reciba las medidas y style
    // la idea es que utilice 3 breakpoints, por eso se necesitan 3 Hidden
    return (
        <>
            <Hidden mdDown>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '72vh',
                            top: '50%',
                            left: '50%',
                            width: '40em',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <Grid2
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}
                        >
                            <Grid2 size={11}>
                                <h2>
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </h2>
                            </Grid2>
                            <Grid2 size={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </button>
                            </Grid2>
                            <Grid2
                                style={{
                                    height: '50vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}
                            >
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}
                                >
                                    {/* <Typography
                gutterBottom
                paragraph
                style={{ textAlign: 'justify' }}> */}
                                    <TermsAndConditionsSignUp />
                                    {/* </Typography> */}
                                </Paper>
                            </Grid2>
                            <Grid2
                                style={{
                                    marginTop: '1em',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={onClick}
                                    size="large"
                                    className="bg-blue-300"
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    {translate(
                                        'termsAndConditions.acceptButton'
                                    )}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Modal>
            </Hidden>
            <Hidden only={['xs', 'md', 'lg', 'xl']}>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '72vh',
                            top: '50%',
                            left: '50%',
                            width: '35em',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <Grid2
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}
                        >
                            <Grid2 size={11}>
                                <h2>
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </h2>
                            </Grid2>
                            <Grid2 size={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </button>
                            </Grid2>
                            <Grid2
                                style={{
                                    height: '50vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}
                            >
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        component="p"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        <TermsAndConditionsSignUp />
                                    </Typography>
                                </Paper>
                            </Grid2>
                            <Grid2
                                style={{
                                    marginTop: '1em',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={onClick}
                                    size="large"
                                    className="bg-blue-300"
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    {translate(
                                        'termsAndConditions.acceptButton'
                                    )}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Modal>
            </Hidden>
            <Hidden smUp>
                <Modal open={open} onClose={onClose}>
                    <Container
                        fixed
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            height: '70vh',
                            top: '50%',
                            left: '50%',
                            width: '20em',
                            transform: 'translate(-50%,-50%)',
                        }}
                    >
                        <Grid2
                            container
                            style={{
                                alignItems: 'center',
                                marginTop: '1em',
                            }}
                        >
                            <Grid2 size={11}>
                                <p
                                    style={{
                                        fontSize: '1.4em',
                                        display: 'inline',
                                    }}
                                >
                                    <strong>
                                        {translate(
                                            'termsAndConditions.link'
                                        ).toUpperCase()}
                                    </strong>
                                </p>
                            </Grid2>
                            <Grid2 size={1}>
                                <button
                                    style={{
                                        background: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </button>
                            </Grid2>
                            <Grid2
                                style={{
                                    height: '50vh',
                                    marginTop: '1em',
                                    overflowX: 'hidden',
                                }}
                            >
                                <Paper
                                    style={{
                                        overflowY: 'auto',
                                        maxHeight: '100%',
                                    }}
                                >
                                    <Typography
                                        gutterBottom
                                        component="p"
                                        style={{ textAlign: 'justify' }}
                                    >
                                        <TermsAndConditionsSignUp />
                                    </Typography>
                                </Paper>
                            </Grid2>
                            <Grid2
                                style={{
                                    marginTop: '1em',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    type="button"
                                    onClick={onClick}
                                    size="large"
                                    className="bg-blue-300"
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    {translate(
                                        'termsAndConditions.acceptButton'
                                    )}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Container>
                </Modal>
            </Hidden>
        </>
    );
};

export default TermsAndConditions;
