import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    triperButton: {
        color: '#ffffff !important',
        lineHeight: '40px !important',
        height: '40px !important',
        textAlign: 'center !important',
        fontSize: '14px !important',
        borderRadius: '4px !important',
        border: 'none !important',
        padding: '0 20px !important',
        cursor: 'pointer !important',
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        backgroundColor: 'transparent',
    },

    bgGreen300: {
        backgroundColor: '#71ba6c !important',
    },

    w100: {
        width: '100% !important',
    },

    inputClass: {
        display: 'flex !important',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '24px',
        // gap: '10px',
        marginBottom: '20px !important',
        // alignItems: 'center !important',
    },
}));
