import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import { Grid2, Button } from '@mui/material';

import { useStyles } from './styles';
import { useTranslation } from 'core/contexts/localization';

import { Modus } from './components/Modus';
import { Enviroment } from './components/Environment';
import { CancelationPolicies } from './components/CancelationPolicies';
import { Includes } from './components/Includes';
import { Location } from './components/Location';
import { Requires } from './components/Requires';
import Timing from './components/Timing';
import BookingPercentage from './components/BookingPercentage';
import { PaxMessage } from './components/PaxMessage';

const SaveForm = ({ originalExperienceInfo }) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();

    return (
        <Grid2 itemAlign={'center'} className={classes.saveModal} size={12}>
            {originalExperienceInfo && originalExperienceInfo.cancelPolicies && originalExperienceInfo.cancelPolicies.additionals.length === 0 && (
                <p
                    style={{
                        textAlign: 'center',
                        fontFamily: 'DM Sans',
                        margin: '0 auto',
                        fontSize: '1rem',
                        width: '85%',
                    }}
                >
                    {translate('experienceProfile.saveModal.cancelationPolicies.noPolicySelected')}
                </p>
            )}
            <p id="save-modal-message" className={classes.smMessage}>
                {translate('experienceProfile.saveModal.message')}
            </p>
        </Grid2>
    );
};

const Content = ({ ...props }) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    console.log('timing', props.experienceInfoTemp);
    console.log('moduses', props.moduses);

    const dinamycTags = {
        1: 'location',
        2: 'modus',
        3: 'enviroment',
        4: 'cancelationPolicies',
        5: 'include',
        6: 'timing',
        7: 'booking',
        8: 'require',
        10: 'pax_message',
    };
    const ViewContent = () => {
        switch (props.contentId) {
            case '1':
                return <Location parseDataToSet={props.parseDataToSet} />;
            case '2':
                return <Modus moduses={props.moduses} experienceInfoTemp={props.experienceInfoTemp} />;
            case '3':
                return <Enviroment experienceInfoTemp={props.experienceInfoTemp} />;
            case '4':
                return <CancelationPolicies experienceInfoTemp={props.experienceInfoTemp} originalExperienceInfo={props.originalExperienceInfo} />;
            case '5':
                return <Includes experienceInfoTemp={props.experienceInfoTemp} contentId={props.contentId} />;
            case '6':
                return <Timing experienceInfoTemp={props.experienceInfoTemp} />;
            case '7':
                return <BookingPercentage experienceInfoTemp={props.experienceInfoTemp} />;
            case '8':
                return <Requires experienceInfoTemp={props.experienceInfoTemp} contentId={props.contentId} />;
            case '10':
                return <PaxMessage experienceInfoTemp={props.experienceInfoTemp} contentId={props.contentId} />;
            default:
            case 'saveForm':
                return <SaveForm handleClose={props.handleClose} originalExperienceInfo={props.originalExperienceInfo} />;
        }
    };
    return (
        <Grid2 size={12} itemAlign={'center'} className={classes.saveModal}>
            <label id="save-modal-title" className={classes.smTitle}>
                {translate(`experienceProfile.saveModal.${!(props.contentId === 'saveForm') ? dinamycTags[props.contentId] + '.' : ''}title`)}
            </label>
            <Divider className={classes.smDivider}></Divider>
            <ViewContent />
            <Grid2 size={12} className={classes.smFooter}>
                <Button
                    onClick={() => {
                        // check NaN timing
                        // if (props.contentId === '6') {
                        //   if (props.experienceInfoTemp

                        if (props.contentId === '4') {
                            // check for cancelation policies whose time is exactly the same
                            if (props.experienceInfoTemp.cancelPolicies.additionals.length > 0) {
                                let sameTime = false;
                                for (let i = 0; i < props.experienceInfoTemp.cancelPolicies.additionals.length; i++) {
                                    if (props.experienceInfoTemp.cancelPolicies.additionals[i].sameTime === true) {
                                        continue;
                                    }
                                    for (let j = i + 1; j < props.experienceInfoTemp.cancelPolicies.additionals.length; j++) {
                                        if (props.experienceInfoTemp.cancelPolicies.additionals[i].time === props.experienceInfoTemp.cancelPolicies.additionals[j].time) {
                                            sameTime = true;
                                            props.experienceInfoTemp.cancelPolicies.additionals[j].sameTime = true;
                                        }
                                    }
                                }

                                // filter the ones with duplicated times
                                props.experienceInfoTemp.cancelPolicies.additionals = props.experienceInfoTemp.cancelPolicies.additionals.filter((item) => !item.sameTime);

                                if (sameTime) {
                                    // alert(translate('experienceProfile.saveModal.cancelationPolicies.sameTime'));
                                    props.setSnackbarMsg(translate('experienceProfile.saveModal.cancelationPolicies.sameTime'));
                                    props.openSnackbar();
                                }
                            }
                        }

                        if (props.contentId === 'saveForm') {
                            props.handleSubmit();
                            props.handleClose();
                        } else {
                            props.saveModalHandler(props.contentId === '4');
                        }
                    }}
                    variant="contained"
                    type="submit"
                    className={classes.smSaveButton}
                >
                    {translate('experienceProfile.saveModal.saveButton')}
                </Button>
                <Button
                    onClick={() => {
                        props.handleClose(props.contentId);
                    }}
                    variant="contained"
                    className={classes.smCancelButton}
                >
                    {translate('experienceProfile.saveModal.cancelButton')}
                </Button>
            </Grid2>
        </Grid2>
    );
};

const SaveModal = ({ ...props }) => {
    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Popover
            style={{
                width: '100%',
                marginTop: window.innerWidth <= 425 ? '200px' : '0px',
            }}
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <Content handleClose={props.handleClose} handleSubmit={props.handleSubmit} contentId={props.contentId} moduses={props.moduses} saveModalHandler={props.saveModalHandler} experienceInfoTemp={props.experienceInfoTemp} parseDataToSet={props.parseDataToSet} originalExperienceInfo={props.originalExperienceInfo} setSnackbarMsg={props.setSnackbarMsg} openSnackbar={props.openSnackbar} />
        </Popover>
    );
};

export default SaveModal;
