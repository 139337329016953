import { useState, useEffect } from 'react';
import { useTranslation } from 'core/contexts/localization';
import makeStyles from '@mui/styles/makeStyles';
import { useMediaQuery } from '@mui/material';
import { TextField, IconButton } from '@mui/material';
import DeleteIcon from 'assets/icons/delete.svg';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    inputLabel: {
        transform: 'translate(12px, 14px) scale(1)',
    },
    inputLabelSmall: {
        transform: 'translate(14.5px, 14px) scale(1)',
    },
}));

const InputRow = ({
    index,
    initialPercentage,
    initialDays,
    onDaysChange,
    initialHours,
    onHoursChange,
    onChangePercentage,
    onRemove,
    disabled,
    ...props
}) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const { t: translate } = useTranslation();
    const classes = useStyles();

    // Bugfix: 0 is not truthy so it was not being displayed on the row, add check for that strict equality to include with truthy values

    const [percentage, setPercentage] = useState(initialPercentage);
    const [days, setDays] = useState(initialDays);
    const [hours, setHours] = useState(initialHours);
    const [isSetTimeVariables, setIsSetTimeVariables] = useState({
        isDaySet: initialDays !== null,
        isHourSet: initialHours !== null,
    });

    useEffect(() => {
        setIsSetTimeVariables({
            isDaySet: initialDays !== null,
            isHourSet: initialHours !== null,
        });
        setDays(initialDays);
        setHours(initialHours);
        setPercentage(initialPercentage);
    }, [initialDays, initialHours, initialPercentage]);

    return (
        <div
            style={{
                display: isMobile ? 'block' : 'flex',
                alignItems: 'baseline',
                boxShadow: 'rgb(0 0 0 / 10%) 5px 5px 15px 5px',
                borderRadius: 4,
                ...props.style,
            }}
        >
            <div style={{ display: isMobile ? 'block' : 'contents' }}>
                <TextField
                    disabled={disabled}
                    label={translate('utils.percentage')}
                    style={isMobile ? { width: '6.5rem' } : { width: '6.5rem' }}
                    value={percentage || percentage === 0 ? percentage : ''}
                    // the following logic doesnt allow an empty value on percentage text field, even temporarily
                    onChange={(e) => {
                        let value = 0;
                        const targetValue = parseInt(e.target.value);
                        if (targetValue > 100) {
                            value = 100;
                        } else if (targetValue < 0 || isNaN(targetValue)) {
                            value = 0;
                        } else {
                            value = targetValue;
                        }
                        setPercentage(value);
                        onChangePercentage(value);
                    }}
                    variant="outlined"
                    type="number"
                    slotProps={{
                        input: {
                            min: 0,
                            max: 100,
                            step: 1,
                            'aria-label': 'cancellation-percentage',
                            endAdornment: (
                                <p style={{ margin: 'auto 0.3rem auto 0' }}>
                                    %
                                </p>
                            ),
                        },
                    }}
                />
                <div
                    style={
                        isMobile
                            ? {
                                  margin: '0.6rem 1rem 0 1rem',
                                  display: 'inline-block',
                              }
                            : {}
                    }
                >
                    <p style={{ display: 'inline', margin: '0 0.25rem' }}>
                        {translate(
                            'experienceProfile.saveModal.cancelationPolicies.ofTheValuePaidWith'
                        )}
                    </p>
                </div>
            </div>

            <TextField
                disabled={disabled}
                label={translate('time_classification.Days')}
                style={
                    isMobile
                        ? { marginTop: '0.7rem', width: '4rem' }
                        : { width: '4rem' }
                }
                value={
                    (days && isSetTimeVariables.isDaySet) || days === 0
                        ? days
                        : ''
                }
                onChange={(e) => {
                    let value = 0;
                    const targetValue = parseInt(e.target.value);
                    if (targetValue > 31) {
                        value = 31;
                    } else if (targetValue < 0 || isNaN(targetValue)) {
                        value = 0;
                    } else {
                        value = targetValue;
                    }

                    if (!isSetTimeVariables.isDaySet) {
                        setIsSetTimeVariables({
                            ...isSetTimeVariables,
                            isDaySet: true,
                        });
                    }
                    setDays(value);
                    onDaysChange(value);
                }}
                variant="outlined"
                type="number"
                slotProps={{
                    input: {
                        min: 0,
                        max: 31,
                        step: 1,
                        'aria-label': 'cancellation-day-anticipation',
                    },
                }}
            />
            <TextField
                disabled={disabled}
                label={translate('time_classification.Hours')}
                style={
                    isMobile
                        ? {
                              marginTop: '0.7rem',
                              width: '4rem',
                              marginLeft: '0.25rem',
                          }
                        : { width: '4rem', marginLeft: '0.25rem' }
                }
                value={
                    (hours && isSetTimeVariables.isHourSet) || hours === 0
                        ? hours
                        : ''
                }
                onChange={(e) => {
                    let value = 0;
                    const targetValue = parseInt(e.target.value);
                    if (targetValue > 999) {
                        value = 999;
                    } else if (targetValue < 0 || isNaN(targetValue)) {
                        value = 0;
                    } else {
                        value = targetValue;
                    }

                    if (!isSetTimeVariables.isHourSet) {
                        setIsSetTimeVariables({
                            ...isSetTimeVariables,
                            isHourSet: true,
                        });
                    }
                    setHours(value);
                    onHoursChange(value);
                }}
                variant="outlined"
                type="number"
                slotProps={{
                    input: {
                        min: 0,
                        max: 999,
                        step: 1,
                        'aria-label': 'cancellation-hour-anticipation',
                    },
                }}
            />
            <div
                style={
                    isMobile
                        ? {
                              margin: '1.3rem 0.7rem 0 0',
                              display: 'inline-block',
                          }
                        : {}
                }
            >
                <p style={{ display: 'inline', marginLeft: '0.25rem' }}>
                    {translate(
                        'experienceProfile.saveModal.cancelationPolicies.ofAnticipation'
                    )}
                </p>
            </div>
            <IconButton
                disabled={disabled}
                style={
                    isMobile
                        ? { margin: 'auto 0.7rem auto 1rem' }
                        : { margin: 'auto 0 auto 1rem' }
                }
                size="small"
                onClick={() => {
                    onRemove();
                }}
                // aria-label={translate('trip.view.destinations.delete')}
                color="primary"
            >
                <img src={DeleteIcon} width={24} height={24} alt="" />
            </IconButton>
        </div>
    );
};

export default InputRow;
