import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: `url('https://source.unsplash.com/O453M2Liufs')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: theme.spacing(4),
    },
    logo: {
        width: '120px',
    },
    itemContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    languageContainer: {
        width: '70vw',
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            margin: '2vh 0',
        },
    },
}));
