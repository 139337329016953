import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    content: {
        height: '100vh',
        // width: '100vw',
        flexGrow: 1,
        // marginTop: 56,
        // marginLeft: 60,
        // marginRight: 60,
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
        },
        padding: '0 0 0 60px',
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: 'white',
    },
}));
