import React from 'react';
import {
    IconButton,
    Container,
    Grid2,
    Paper,
    Typography,
    Checkbox,
    FormControlLabel,
} from '@mui/material';

import CheckboxComponent from 'core/libs/core-ui/components/Input/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';

const ResourceExperienceCard = ({
    resource,
    onUpdate = () => {},
    onDelete = () => {},
    ...props
}) => {
    return (
        <Container>
            <Paper
                style={{
                    padding: 16,
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                <Grid2 container alignItems="flex-start" spacing={2}>
                    <Grid2 size={12} container justifyContent="flex-end">
                        <IconButton
                            aria-label="delete"
                            onClick={() => onDelete(resource.id)}
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 8 }}>
                        <Grid2 container>
                            <Grid2
                                size={12}
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid2>
                                    <Typography variant="h5">
                                        {resource?.name}
                                    </Typography>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={12}>
                                <Typography variant="subtitle1">
                                    {resource?.resourceCategoryName}
                                </Typography>
                            </Grid2>
                        </Grid2>
                        <Grid2
                            container
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            spacing={2}
                        >
                            <Grid2 style={{ marginTop: '0.5rem' }} size={12}>
                                <Typography variant="body1">
                                    {resource?.email}
                                </Typography>
                                <Typography variant="body1">
                                    {resource?.mobilePhone}
                                </Typography>
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={{ xs: 12, sm: 4 }}>
                        <Grid2
                            container
                            spacing={1}
                            style={{ flexDirection: 'column' }}
                            display="flex"
                        >
                            <Grid2 size={{ xs: 12, sm: 6 }}>
                                <CheckboxComponent
                                    labelComponent={'Email Notification'}
                                    checked={
                                        resource?.notificationEmail || false
                                    }
                                    onChange={(value) =>
                                        onUpdate({
                                            ...resource,
                                            notificationEmail: value,
                                        })
                                    }
                                    disabled={!resource?.email}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 6 }}>
                                <CheckboxComponent
                                    labelComponent={'Whatsapp Notification'}
                                    checked={
                                        resource?.notificationWhatsapp || false
                                    }
                                    disabled
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Paper>
        </Container>
    );
};

export default ResourceExperienceCard;
