import { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Button, Link, CardActions, CircularProgress, Typography, Card, FormControlLabel, InputAdornment, IconButton } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useAuth } from 'core/contexts/auth';
import { useTranslation } from 'core/contexts/localization';
import TextInput from 'components/Input/Text';
import { useStyles } from './styles';
import { validateSignUp } from 'core/packages/validate';
import Checkbox from './Checkbox';
import TermsAndConditions from './TermsAndConditions';
import { toast } from 'react-toastify';

const SignUp = () => {
    const { t: translate } = useTranslation();
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [registrationLogin, setRegistrationLogin] = useState(false);

    const { signUp, loading } = useAuth();

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const validateSignUp = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate('form.required');
        } else if (!validateEmail(values.email)) {
            errors.email = translate('form.invalid.email');
        }
        if (!values.password) {
            errors.password = translate('form.required');
        }
        return errors;
    };

    const onSubmit = async (values) => {
        setSubmitted(true);
        setRegistrationLogin(true);
        console.log('INSIDE ON SUBMIT === ', values, '==', credentials);
        if (!acceptedTerms) {
            return;
        }
        const successSignUp = await signUp(credentials);
        if (successSignUp) {
            toast.success(translate('landing.sign_up.successStart') + `${credentials.email}` + translate('landing.sign_up.successEnd'), {
                position: 'top-right',
                theme: 'colored',
            });
        }
        setRegistrationLogin(false);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const renderTermsAndConditions = () => {
        return (
            <>
                {translate('termsAndConditions.accept')}
                <Link
                    href=""
                    style={{ color: '#1890ff' }}
                    onClick={(e) => {
                        console.log('CLICKED TERM AND CONDITIONS', e);
                        setOpenModal(true);
                        e.preventDefault();
                    }}
                >
                    {translate('termsAndConditions.link')}
                </Link>
            </>
        );
    };

    return (
        <>
            <Form
                onSubmit={onSubmit}
                validate={validateSignUp}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.card}>
                            <Typography variant="h4" component="h1" align="center">
                                {translate('landing.sign_up.title')}
                            </Typography>
                            <div>
                                <Field
                                    name="email"
                                    component={TextInput}
                                    onFieldChange={(text) => {
                                        setCredentials({
                                            ...credentials,
                                            email: text,
                                        });
                                    }}
                                    label={translate('landing.sign_up.fields.email')}
                                    disabled={loading}
                                    className={classes.input}
                                />
                                <Field
                                    name="password"
                                    component={TextInput}
                                    onFieldChange={(text) => {
                                        setCredentials({
                                            ...credentials,
                                            password: text,
                                        });
                                    }}
                                    label={translate('landing.sign_up.fields.password')}
                                    type={showPassword ? 'text' : 'password'}
                                    disabled={loading}
                                    className={classes.input}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} size="large">
                                                    {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Field
                                    style={{
                                        paddingTop: '2em',
                                    }}
                                    hasError={submitted && !acceptedTerms}
                                    errorMessage={translate('form.required')}
                                    checked={acceptedTerms}
                                    onChange={(value) => {
                                        setAcceptedTerms(value);
                                        if (submitted) setSubmitted(false);
                                    }}
                                    name="termsAndConditions"
                                    component={Checkbox}
                                    labelComponent={renderTermsAndConditions()}
                                />
                            </div>
                            <CardActions className={classes.actions}>
                                <Button variant="contained" type="button" color="primary" disabled={loading} fullWidth className={classes.submitButton} onClick={async (values) => await onSubmit(values)}>
                                    {loading & registrationLogin ? <CircularProgress size={25} thickness={4} /> : translate('landing.sign_up.button')}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            />
            <TermsAndConditions
                open={openModal}
                onClose={() => setOpenModal(false)}
                onClick={() => {
                    if (!acceptedTerms) setAcceptedTerms(true);
                    setOpenModal(false);
                    if (submitted) setSubmitted(false);
                }}
            />
        </>
    );
};

SignUp.displayName = 'SignUp';

export default SignUp;
