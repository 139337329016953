import { useEffect } from 'react';
import { Grid2 } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Login from './components/Login';
import { useStyles } from './styles';

import SignUp from './components/SignUp';
import { useAuth } from 'core/contexts/auth';
import { ROUTES } from 'core/routing/routes';
import logo from 'assets/images/utriper-black-logo.png';
import LanguageDropDown from 'components/LanguageDropDown';

const LandingPage = () => {
    const classes = useStyles();
    const { authInfo } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (authInfo.token) {
            history.push(ROUTES.HOME);
        }
    }, [authInfo]);

    return (
        <div className={classes.main}>
            <img src={logo} className={classes.logo} />
            <div className={classes.languageContainer}>
                <LanguageDropDown
                    style={{ marginRight: 0, marginLeft: 'auto' }}
                />
            </div>
            <Grid2
                container
                spacing={9}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Grid2
                    size={{ xs: 12, md: 6 }}
                    className={classes.itemContainer}
                >
                    <Login />
                </Grid2>
                <Grid2
                    size={{ xs: 12, md: 6 }}
                    className={classes.itemContainer}
                >
                    <SignUp />
                </Grid2>
            </Grid2>
        </div>
    );
};

LandingPage.displayName = 'LandingPage';

export default LandingPage;
